import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Switch } from '@mui/material';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import { TableSortLabel } from '@mui/material';
import { FormControl } from '@mui/material';
import { Slider, Box } from '@mui/material';
import { motion, AnimatePresence } from 'framer-motion';
import { SeasonDropdown, LeagueDropdown, TeamDropdown, PositionDropdown, RosterTypeDropdown, CategoryDropdown } from './Dropdowns';
import LoadingAnimation from './LoadingAnimation';
import './ImpactMetricsTable.css';
import PageTransition from './PageTransition';
import { seasonsShotData, rosterTypeMapping, positionOrder } from './constants';
import ColoredCell, { calculatePercentiles} from './ColoringLogic';
import { sortData, getComparator } from './SortingLogic';
import { Link } from 'react-router-dom';

const baseUrl = process.env.REACT_APP_API_URL;

const PlayerShotQuality = () => {
    const [seasonId, setSeasonId] = useState('202324');
    const [leagueId, setLeagueId] = useState('1');
    const [category, setCategory] = useState('all');

    const [data, setData] = useState([]);
    const [error, setError] = useState(null);
    const [percentiles, setPercentiles] = useState({});
    const [orderDirection, setOrderDirection] = useState('desc');
    const [orderBy, setOrderBy] = useState('qsp');
    const [teamFilter, setTeamFilter] = useState([]);
    const [positionFilter, setPositionFilter] = useState([]);
    const [playerClassFilter, setPlayerClassFilter] = useState([]);
    const [attemptsFilter, setAttemptsFilter] = useState(50);
    const [appliedAttemptsFilter, setAppliedAttemptsFilter] = useState(50);
    const [maxAttempts, setMaxAttempts] = useState(2000);
    const [showJapanese, setShowJapanese] = useState(true);
    const [uniqueTeamsJP, setUniqueTeamsJP] = useState([]);
    const [uniqueTeamsEN, setUniqueTeamsEN] = useState([]);
    const [teamNameMapping, setTeamNameMapping] = useState({});
    const [isLoading, setIsLoading] = useState(false);

    const responsiveStyle = window.innerWidth <= 600 ? { fontSize: '11px' } : {};
    const responsiveCellStyle = window.innerWidth <= 600 ? { padding: '2px', minWidth: '30px' } : { padding: '2px', minWidth: '80px' };
    const responsiveRowStyle = window.innerWidth <= 600 ? { padding: '0px', minHeight: '10px' } : { padding: '0px', minHeight: '10px' };

    useEffect(() => {
        setIsLoading(true);
        axios.get(`${baseUrl}/api/player-shot-quality/?season_id=${seasonId}&league_id=${leagueId}&category=${category}`)
            .then(response => {
                setData(response.data);
                setMaxAttempts(Math.max(...response.data.map(player => player.fga)));
                setPercentiles(calculatePercentiles(response.data, ['fga', 'frequency', 'pts', 'xpts', 'points_added', 'fg_percent', 'efg', 'qsq', 'qsi', 'qsp']));
                const teamNameMapping = createTeamNameMapping(response.data);
                setTeamNameMapping(teamNameMapping);
                setUniqueTeamsJP(getUniqueTeams(response.data, 'team_names', teamNameMapping));
                setUniqueTeamsEN(getUniqueTeams(response.data, 'team_names_eng', teamNameMapping));
                setIsLoading(false);
            })
            .catch(error => {
                console.error("Failed to fetch shot quality data", error);
                setError("Failed to fetch data");
                setIsLoading(false);
            });
    }, [seasonId, leagueId, category]);

    // Get unique team names (either Japanese or English) sorted by team ID
    const getUniqueTeams = (data, teamProp, teamNameMapping) => {
        const uniqueTeams = [...new Set(data.flatMap(player => player[teamProp]))].sort((a, b) => {
            return teamNameMapping[a]?.team_id - teamNameMapping[b]?.team_id;
        });
        return uniqueTeams;
    };

    // Create a mapping of team names to team IDs and English names
    const createTeamNameMapping = (data) => {
        return data.reduce((acc, player) => {
            player.team_names.forEach((teamName, index) => {
                acc[teamName] = {
                    team_id: player.team_ids[index],
                    team_name_eng: player.team_names_eng[index]
                };
            });
            return acc;
        }, {});
    };

    const handleLanguageToggle = () => {
        setShowJapanese(!showJapanese);
        if (teamFilter.length) {
            setTeamFilter(showJapanese
                ? teamFilter.map(team => teamNameMapping[team] || team)
                : teamFilter.map(team => Object.keys(teamNameMapping).find(key => teamNameMapping[key] === team) || team)
            );
        }
    };

    const commonCellStyle = {
        transition: 'all 0.2s ease',
    };

    const handleSortRequest = (columnId) => {
        const isAsc = orderBy === columnId && orderDirection === 'desc';
        setOrderDirection(isAsc ? 'asc' : 'desc');
        setOrderBy(columnId);
    };

    const filteredData = data.filter((player) => {
        const playerTeams = showJapanese ? player.team_names : player.team_names_eng;
        return (
            (!teamFilter.length || teamFilter.some(team => playerTeams.includes(team))) &&
            (!positionFilter.length || positionFilter.includes(player.position)) &&
            (!playerClassFilter || playerClassFilter.length === 0 || playerClassFilter.includes(player.player_class)) &&
            (player.fga >= appliedAttemptsFilter)
        );
    });

    const sortedAndFilteredData = sortData(filteredData, getComparator(orderDirection, orderBy));

    // Position order
    const uniquePositions = [...new Set(data.flatMap((player) => player.position.split('/')))];
    const orderedPositions = positionOrder.filter(position => uniquePositions.includes(position));

    const handleSliderChangeCommitted = (event, newValue) => {
        setAppliedAttemptsFilter(newValue);
    };

    return (
        <PageTransition>
            <Container maxWidth="lg" sx={{ my: 10 }}>
                <Typography variant="h4" gutterBottom className="impact-metrics-title">
                    B.LEAGUE <span className="impact-metrics-break">Player Shooting Statistics</span>
                </Typography>
                <Box sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: 2, // Adds space between the two rows
                }}>
                    <Box sx={{
                        display: "flex",
                        flexWrap: "wrap",
                        alignItems: "center",
                        gap: 1, // Adds space between items in the first row
                    }}>
                        <SeasonDropdown
                            currentSeasonId={seasonId}
                            setSeasonId={setSeasonId}
                            seasons={seasonsShotData}
                            className="impact-metrics-formControl"
                        />
                        <LeagueDropdown
                            selectedLeague={leagueId}
                            setLeagueId={setLeagueId}
                            className="impact-metrics-formControl"
                        />
                        <CategoryDropdown
                            category={category}
                            setCategory={setCategory}
                            className="impact-metrics-formControl"
                        />
                    </Box>
                    <Box sx={{
                        display: "flex",
                        flexWrap: "wrap",
                        alignItems: "center",
                        gap: 1, // Adds space between items in the second row
                    }}>
                        <TeamDropdown
                            teamFilter={teamFilter}
                            setTeamFilter={setTeamFilter}
                            uniqueTeams={showJapanese ? uniqueTeamsJP : uniqueTeamsEN}
                            showJapanese={showJapanese}
                            className="impact-metrics-formControl"
                        />
                        <PositionDropdown
                            positionFilter={positionFilter}
                            setPositionFilter={setPositionFilter}
                            orderedPositions={orderedPositions}
                            className="impact-metrics-formControl"
                        />
                        <RosterTypeDropdown
                            playerClassFilter={playerClassFilter}
                            setPlayerClassFilter={setPlayerClassFilter}
                            rosterTypeMapping={rosterTypeMapping}
                            showJapanese={showJapanese}
                            className="impact-metrics-formControl"
                        />
                        <FormControl className="impact-metrics-slider" variant="outlined" sx={{ m: 1, minWidth: 200, height: 56, display: 'flex', flexDirection: 'row', alignItems: 'center', border: '1px solid #c4c4c4', borderRadius: '4px', p: 1 }}>
                        <Typography sx={{ mr: 2 }} variant="body2">Total Attempts</Typography>
                            <Slider
                                value={attemptsFilter}
                                onChange={(event, newValue) => setAttemptsFilter(newValue)}
                                onChangeCommitted={handleSliderChangeCommitted}
                                aria-labelledby="min-slider"
                                valueLabelDisplay="auto"
                                min={0}
                                max={parseInt(maxAttempts, 10)}
                                sx={{ flexGrow: 1 }}
                            />
                        </FormControl>
                    </Box>
                </Box>
                <Box
                    sx={{
                        display: "flex",
                        flexWrap: { xs: 'wrap', sm: 'nowrap' },
                        alignItems: "center",
                        overflowX: 'auto'
                    }}
                >
                    <Typography variant="body2" sx={{ mr: 1 }}>
                        English
                    </Typography>
                    <Switch
                        checked={!showJapanese}
                        onChange={handleLanguageToggle} // Updated handler
                        name="languageToggle"
                        inputProps={{ 'aria-label': 'language toggle' }}
                    />
                </Box>
                <div style={{ overflowX: 'auto' }}>
                    <TableContainer>
                        {error && <p>{error}</p>}
                        <AnimatePresence mode="wait">
                            {isLoading ? (
                                <motion.div
                                    key="loading"
                                    initial={{ opacity: 0 }}
                                    animate={{ opacity: 1 }}
                                    exit={{ opacity: 0 }}
                                    transition={{ duration: 0.3 }}
                                >
                                    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100px' }}>
                                        <LoadingAnimation />
                                    </Box>
                                </motion.div>
                            ) : (
                                <motion.div
                                    key="table"
                                    initial={{ opacity: 0 }}
                                    animate={{ opacity: 1 }}
                                    exit={{ opacity: 0 }}
                                    transition={{ duration: 0.5 }}
                                >
                                    <Table className="table-auto w-full" aria-label="Shot Quality Table">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell component="th" style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', ...responsiveStyle, ...responsiveCellStyle }}>
                                                    <TableSortLabel
                                                        active={false}
                                                        direction={'asc'}
                                                    >
                                                        Rank
                                                    </TableSortLabel>
                                                </TableCell>
                                                <TableCell
                                                    component="th"
                                                    className="sticky left-0 z-10 bg-white responsive-table-header"
                                                >
                                                    <Typography
                                                        sx={{
                                                            whiteSpace: 'nowrap',
                                                            overflow: 'hidden',
                                                            textOverflow: 'ellipsis',
                                                            minWidth: '120px',
                                                            position: 'sticky',
                                                            left: 0,
                                                            zIndex: 10,
                                                        }}
                                                        style={{ ...responsiveStyle, ...responsiveCellStyle }}
                                                    >
                                                        Player
                                                    </Typography>
                                                </TableCell>
                                                <TableCell
                                                    component="th"
                                                    style={{
                                                        whiteSpace: 'nowrap',
                                                        overflow: 'hidden',
                                                        textOverflow: 'ellipsis',
                                                        textAlign: 'center', // Add this line
                                                        ...responsiveStyle,
                                                        ...responsiveCellStyle
                                                    }}
                                                >
                                                    <TableSortLabel
                                                        active={orderBy === 'age'}
                                                        direction={orderBy === 'age' ? orderDirection : 'desc'}
                                                        onClick={() => handleSortRequest('age')}
                                                    >
                                                        Age
                                                    </TableSortLabel>
                                                </TableCell>
                                                <TableCell
                                                    component="th"
                                                    style={{
                                                        whiteSpace: 'nowrap',
                                                        overflow: 'hidden',
                                                        textOverflow: 'ellipsis',
                                                        textAlign: 'center', // Add this line
                                                        ...responsiveStyle,
                                                        ...responsiveCellStyle
                                                    }}
                                                >
                                                    <TableSortLabel
                                                        active={orderBy === 'fga'}
                                                        direction={orderBy === 'fga' ? orderDirection : 'desc'}
                                                        onClick={() => handleSortRequest('fga')}
                                                    >
                                                        FGA
                                                    </TableSortLabel>
                                                </TableCell>
                                                <TableCell
                                                    component="th"
                                                    style={{
                                                        whiteSpace: 'nowrap',
                                                        overflow: 'hidden',
                                                        textOverflow: 'ellipsis',
                                                        textAlign: 'center', // Add this line
                                                        ...responsiveStyle,
                                                        ...responsiveCellStyle
                                                    }}
                                                >
                                                    <TableSortLabel
                                                        active={orderBy === 'frequency'}
                                                        direction={orderBy === 'frequency' ? orderDirection : 'desc'}
                                                        onClick={() => handleSortRequest('frequency')}
                                                    >
                                                        FREQ%
                                                    </TableSortLabel>
                                                </TableCell>
                                                <TableCell
                                                    component="th"
                                                    style={{
                                                        whiteSpace: 'nowrap',
                                                        overflow: 'hidden',
                                                        textOverflow: 'ellipsis',
                                                        textAlign: 'center', // Add this line
                                                        ...responsiveStyle,
                                                        ...responsiveCellStyle
                                                    }}
                                                >
                                                    <TableSortLabel
                                                        active={orderBy === 'pts'}
                                                        direction={orderBy === 'pts' ? orderDirection : 'desc'}
                                                        onClick={() => handleSortRequest('pts')}
                                                    >
                                                        PTS
                                                    </TableSortLabel>
                                                </TableCell>
                                                <TableCell
                                                    component="th"
                                                    style={{
                                                        whiteSpace: 'nowrap',
                                                        overflow: 'hidden',
                                                        textOverflow: 'ellipsis',
                                                        textAlign: 'center', // Add this line
                                                        ...responsiveStyle,
                                                        ...responsiveCellStyle
                                                    }}
                                                >
                                                    <TableSortLabel
                                                        active={orderBy === 'xpts'}
                                                        direction={orderBy === 'xpts' ? orderDirection : 'desc'}
                                                        onClick={() => handleSortRequest('xpts')}
                                                    >
                                                        xPTS
                                                    </TableSortLabel>
                                                </TableCell>
                                                <TableCell
                                                  component="th"
                                                  style={{
                                                    whiteSpace: 'nowrap',
                                                    overflow: 'hidden',
                                                    textOverflow: 'ellipsis',
                                                    textAlign: 'center',
                                                    ...responsiveStyle,
                                                    ...responsiveCellStyle
                                                  }}
                                                >
                                                  <TableSortLabel
                                                    active={orderBy === 'points_added'}
                                                    direction={orderBy === 'points_added' ? orderDirection : 'desc'}
                                                    onClick={() => handleSortRequest('points_added')}
                                                    style={{ whiteSpace: 'pre-wrap' }} // Add this style
                                                  >
                                                    Points
                                                    <br />
                                                    Added
                                                  </TableSortLabel>
                                                </TableCell>
                                                <TableCell
                                                    component="th"
                                                    style={{
                                                        whiteSpace: 'nowrap',
                                                        overflow: 'hidden',
                                                        textOverflow: 'ellipsis',
                                                        textAlign: 'center', // Add this line
                                                        ...responsiveStyle,
                                                        ...responsiveCellStyle
                                                    }}
                                                >
                                                    <TableSortLabel
                                                        active={orderBy === 'fg_percent'}
                                                        direction={orderBy === 'fg_percent' ? orderDirection : 'desc'}
                                                        onClick={() => handleSortRequest('fg_percent')}
                                                    >
                                                        FG%
                                                    </TableSortLabel>
                                                </TableCell>
                                                <TableCell
                                                    component="th"
                                                    style={{
                                                        whiteSpace: 'nowrap',
                                                        overflow: 'hidden',
                                                        textOverflow: 'ellipsis',
                                                        textAlign: 'center', // Add this line
                                                        ...responsiveStyle,
                                                        ...responsiveCellStyle
                                                    }}
                                                >
                                                    <TableSortLabel
                                                        active={orderBy === 'efg'}
                                                        direction={orderBy === 'efg' ? orderDirection : 'desc'}
                                                        onClick={() => handleSortRequest('efg')}
                                                    >
                                                        eFG%
                                                    </TableSortLabel>
                                                </TableCell>
                                                <TableCell
                                                    component="th"
                                                    style={{
                                                        whiteSpace: 'nowrap',
                                                        overflow: 'hidden',
                                                        textOverflow: 'ellipsis',
                                                        textAlign: 'center', // Add this line
                                                        ...responsiveStyle,
                                                        ...responsiveCellStyle
                                                    }}
                                                >
                                                    <TableSortLabel
                                                        active={orderBy === 'qsq'}
                                                        direction={orderBy === 'qsq' ? orderDirection : 'desc'}
                                                        onClick={() => handleSortRequest('qsq')}
                                                    >
                                                        qSQ
                                                    </TableSortLabel>
                                                </TableCell>
                                                <TableCell
                                                    component="th"
                                                    style={{
                                                        whiteSpace: 'nowrap',
                                                        overflow: 'hidden',
                                                        textOverflow: 'ellipsis',
                                                        textAlign: 'center', // Add this line
                                                        ...responsiveStyle,
                                                        ...responsiveCellStyle
                                                    }}
                                                >
                                                    <TableSortLabel
                                                        active={orderBy === 'qsi'}
                                                        direction={orderBy === 'qsi' ? orderDirection : 'desc'}
                                                        onClick={() => handleSortRequest('qsi')}
                                                    >
                                                        qSI
                                                    </TableSortLabel>
                                                </TableCell>
                                                <TableCell
                                                    component="th"
                                                    style={{
                                                        whiteSpace: 'nowrap',
                                                        overflow: 'hidden',
                                                        textOverflow: 'ellipsis',
                                                        textAlign: 'center', // Add this line
                                                        ...responsiveStyle,
                                                        ...responsiveCellStyle
                                                    }}
                                                >
                                                    <TableSortLabel
                                                        active={orderBy === 'qsp'}
                                                        direction={orderBy === 'qsp' ? orderDirection : 'desc'}
                                                        onClick={() => handleSortRequest('qsp')}
                                                    >
                                                        qSP
                                                    </TableSortLabel>
                                                </TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {sortedAndFilteredData.map((player, index) => (
                                                <TableRow
                                                    key={`${player.player_id}_${player.team_ids[0]}`}
                                                    sx={{
                                                        '& > *': { py: '2px' },
                                                        '&:hover': {
                                                            '& .MuiTableCell-root:not(.player-name-cell)': {
                                                                filter: 'brightness(90%)',
                                                            },
                                                            '& .player-name-cell': {
                                                                backgroundColor: 'rgb(240, 238, 245)',
                                                            },
                                                        },
                                                    }}
                                                >
                                                    <TableCell className="left-column" sx={commonCellStyle} style={responsiveStyle}>{index + 1}</TableCell>
                                                    <TableCell
                                                        component="th"
                                                        scope="row"
                                                        className="sticky left-0 z-10 player-name-cell"
                                                        sx={{
                                                            ...commonCellStyle,
                                                            backgroundColor: 'rgb(250, 248, 255)',
                                                            position: 'sticky',
                                                            left: 0,
                                                            zIndex: 10,
                                                        }}
                                                    >
                                                        <Link
                                                            to={`/player-profile/${player.player_id}`}
                                                            style={{ textDecoration: 'none', color: 'inherit' }}
                                                            className="player-link"
                                                        >
                                                            <Typography
                                                                variant="body1"
                                                                sx={{
                                                                    color: 'black',
                                                                    fontSize: '0.875rem',
                                                                    whiteSpace: 'nowrap',
                                                                    overflow: 'hidden',
                                                                    textOverflow: 'ellipsis',
                                                                    maxWidth: '100%',
                                                                    textDecoration: 'underline',
                                                                    '&:hover': {
                                                                        color: '#8685EF'
                                                                    }
                                                                }}
                                                                style={{ ...responsiveStyle, ...responsiveRowStyle }}
                                                            >
                                                                {showJapanese ? player.player_name : player.player_name_eng}
                                                            </Typography>
                                                        </Link>
                                                        <Typography
                                                            variant="body2"
                                                            sx={{
                                                                color: 'grey',
                                                                fontSize: '0.75rem',
                                                                whiteSpace: 'nowrap',
                                                                overflow: 'hidden',
                                                                textOverflow: 'ellipsis',
                                                                maxWidth: '100%'
                                                            }}
                                                            style={{ ...responsiveStyle, ...responsiveRowStyle }}
                                                        >
                                                            {showJapanese ? player.team_names[0] : player.team_names_eng[0]} - {player.position}
                                                        </Typography>
                                                    </TableCell>
                                                    <TableCell
                                                        className="left-column"
                                                        sx={{
                                                            ...commonCellStyle,
                                                            textAlign: 'center'
                                                        }}
                                                        style={responsiveStyle}
                                                    >
                                                        {player.age}
                                                    </TableCell>
                                                    <ColoredCell
                                                        value={player.fga}
                                                        percentile={percentiles['fga']?.[player.fga]}
                                                        format={value => `${value}`}
                                                        sx={commonCellStyle}
                                                    />
                                                    <ColoredCell
                                                        value={player.frequency}
                                                        percentile={percentiles['frequency']?.[player.frequency]}
                                                        format={(value) => `${(value * 100).toFixed(1)}%`}
                                                        sx={commonCellStyle}
                                                    />
                                                    <ColoredCell
                                                        value={player.pts}
                                                        percentile={percentiles['pts']?.[player.pts]}
                                                        format={value => `${value}`}
                                                        sx={commonCellStyle}
                                                    />
                                                    <ColoredCell
                                                        value={player.xpts}
                                                        percentile={percentiles['xpts']?.[player.xpts]}
                                                        format={value => `${value.toFixed(1)}`}
                                                        sx={commonCellStyle}
                                                    />
                                                    <ColoredCell
                                                        value={player.points_added}
                                                        percentile={percentiles['points_added']?.[player.points_added]}
                                                        format={value => `${value.toFixed(1)}`}
                                                        sx={commonCellStyle}
                                                    />
                                                    <ColoredCell
                                                        value={player.fg_percent}
                                                        percentile={percentiles['fg_percent']?.[player.fg_percent]}
                                                        format={(value) => `${(value * 100).toFixed(1)}%`}
                                                        sx={commonCellStyle}
                                                    />
                                                    <ColoredCell
                                                        value={player.efg}
                                                        percentile={percentiles['efg']?.[player.efg]}
                                                        format={(value) => `${(value * 100).toFixed(1)}%`}
                                                        sx={commonCellStyle}
                                                    />
                                                    <ColoredCell
                                                        value={player.qsq}
                                                        percentile={percentiles['qsq']?.[player.qsq]}
                                                        format={(value) => `${(value * 100).toFixed(1)}%`}
                                                        sx={commonCellStyle}
                                                    />
                                                    <ColoredCell
                                                        value={player.qsi}
                                                        percentile={percentiles['qsi']?.[player.qsi]}
                                                        format={value => value > 0 ? `+${(value * 100).toFixed(1)}%` : `${(value * 100).toFixed(2)}%`}
                                                        sx={commonCellStyle}
                                                    />
                                                    <ColoredCell
                                                        value={player.qsp}
                                                        percentile={percentiles['qsp']?.[player.qsp]}
                                                        format={(value) => `${(value * 100).toFixed(1)}%`}
                                                        sx={commonCellStyle}
                                                    />
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </motion.div>
                            )}
                        </AnimatePresence>
                    </TableContainer>
                </div>
            </Container>
        </PageTransition>
    );
};

export default PlayerShotQuality;