import React from 'react';
import { Typography, Container, Divider, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Link } from '@mui/material';
import { createTheme, ThemeProvider, styled } from '@mui/material/styles'
import progressionImage from '../images/14301_progression.png';
import diagramImage from '../images/diagram_en.png';
import './GPMExplanation.css';

const theme = createTheme({
    typography: {
        fontFamily: 'Roboto, sans-serif',
    },
});

const StyledTableContainer = styled(TableContainer)(({ theme }) => ({
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    padding: theme.spacing(2),
    maxWidth: 600,
    margin: '0 auto',
}));

const GPMExplanationEnglish = () => {
    const coefficients = [
        { variable: '2pm_p100', offensiveCoef: 0.9483799741500631, defenseCoef: 0.01993381108305096 },
        { variable: '2pa_p100', offensiveCoef: -0.39668327470586445, defenseCoef: -0.08863174161466622 },
        { variable: '3pm_p100', offensiveCoef: 1.439600863861926, defenseCoef: 0.1869176528911269 },
        { variable: '3pa_p100', offensiveCoef: -0.24339791216566434, defenseCoef: -0.046921137099587326 },
        { variable: 'ftm_p100', offensiveCoef: 0.68040739678691, defenseCoef: -0.1277251855525012 },
        { variable: 'fta_p100', offensiveCoef: -0.36174796963524386, defenseCoef: 0.12925413752168136 },
        { variable: 'oreb_p100', offensiveCoef: 0.38273604763848257, defenseCoef: 0.18635541649017787 },
        { variable: 'dreb_p100', offensiveCoef: -0.08117063304256983, defenseCoef: 0.08022843247274228 },
        { variable: 'ast_p100', offensiveCoef: 0.3554811216608242, defenseCoef: 0.17671812908161127 },
        { variable: 'stl_p100', offensiveCoef: 0.1652870064059297, defenseCoef: 0.6759657594829205 },
        { variable: 'blk_p100', offensiveCoef: -0.3208913548639053, defenseCoef: 0.6198466856576473 },
        { variable: 'tov_p100', offensiveCoef: -0.44744501506738665, defenseCoef: -0.24477435852614324 },
        { variable: 'pf_p100', offensiveCoef: -0.07286037606613935, defenseCoef: -0.03428207657011195 },
        { variable: 'Intercept', offensiveCoef: -3.1607567696276497, defenseCoef: -1.7343927280062554 },
    ];

    const paddingNumber = [
        { variable: '2pa_p100', paddingDenominator: 'poss', paddingValue: 46.45140634 },
        { variable: '2pt%', paddingDenominator: '2pa', paddingValue: 33.26763492 },
        { variable: '3pa_p100', paddingDenominator: 'poss', paddingValue: 42.32571546 },
        { variable: '3pt%', paddingDenominator: '3pa', paddingValue: 99.2809109 },
        { variable: 'fta_p100', paddingDenominator: 'poss', paddingValue: 112.6560117 },
        { variable: 'ft%', paddingDenominator: 'fta', paddingValue: 23.45084608 },
        { variable: 'orb_p100', paddingDenominator: 'poss', paddingValue: 83.62754119 },
        { variable: 'drb_p100', paddingDenominator: 'poss', paddingValue: 60.78853624 },
        { variable: 'ast_p100', paddingDenominator: 'poss', paddingValue: 65.27466162 },
        { variable: 'stl_p100', paddingDenominator: 'poss', paddingValue: 519.5630209 },
        { variable: 'blk_p100', paddingDenominator: 'poss', paddingValue: 184.1617414 },
        { variable: 'tov_p100', paddingDenominator: 'poss', paddingValue: 276.7496837 },
        { variable: 'pf_p100', paddingDenominator: 'poss', paddingValue: 106.8909181 },
    ];

    return (
        <ThemeProvider theme={theme}>
            <Container maxWidth="md" className="gpm-explanation">
                <img src={progressionImage} alt="Progression" style={{ width: '100%', marginBottom: theme.spacing(2) }} />
                <Typography variant="h4" component="h1" gutterBottom>
                    Introducing Guesstimated Plus-Minus
                </Typography>
                <Typography variant="body1" paragraph>
                    Guesstimated Plus-Minus (GPM) is an all-in-one metric that estimates individual player impact to his team in points per 100 possessions. GPM is mostly built on the metrics existing in the NBA circle, but while picking and choosing the concepts I like, I made some deliberate adjustments that I think better fit the context of the B League. I would like to personally thank everyone I am about to mention in this explanation, as this could not be possible without their contribution in the NBA circle and their generosity to share it to the public. The overall methodology of GPM will be to feed a SPM Prior to a {' '}
                    <Link href="https://squared2020.com/2017/09/18/deep-dive-on-regularized-adjusted-plus-minus-i-introductory-example/" target="_blank" rel="noopener noreferrer" color="#8685EF" sx={{ textDecoration: 'none', '&:hover': { color: "#65FBD2", textDecoration: 'underline' } }}>
                        RAPM
                    </Link>
                    {' '} model, which is widely called Bayesian-RAPM. As far as I understand, Jerry Engelmann's {' '}
                    <Link href="https://www.espn.co.uk/nba/story/_/id/10740818/introducing-real-plus-minus" target="_blank" rel="noopener noreferrer" color="#8685EF" sx={{ textDecoration: 'none', '&:hover': { color: "#65FBD2", textDecoration: 'underline' } }}>
                        xRAPM (ESPN's former RPM model)
                    </Link>
                    , Taylor Snarr's {' '}
                    <Link href="https://dunksandthrees.com/about/epm" target="_blank" rel="noopener noreferrer" color="#8685EF" sx={{ textDecoration: 'none', '&:hover': { color: "#65FBD2", textDecoration: 'underline' } }}>
                        EPM
                    </Link>
                    {' '} and Krishna Narsu's {' '}
                    <Link href="https://www.bball-index.com/lebron-introduction/" target="_blank" rel="noopener noreferrer" color="#8685EF" sx={{ textDecoration: 'none', '&:hover': { color: "#65FBD2", textDecoration: 'underline' } }}>
                        LEBRON
                    </Link>
                    {' '} all follow this framework.
                </Typography>
                <Divider style={{ margin: '20px 0' }} />
                <Typography variant="h5" component="h2" gutterBottom>
                    Motivation
                </Typography>
                <Typography variant="body1" paragraph>
                    I have been interested in these all-in-one metrics for a while, and even replicated Jacob Goldstein's {' '}
                    <Link href="https://www.bball-index.com/player-impact-plus-minus/" target="_blank" rel="noopener noreferrer" color="#8685EF" sx={{ textDecoration: 'none', '&:hover': { color: "#65FBD2", textDecoration: 'underline' } }}>
                        PIPM
                    </Link>
                    {' '} and Daniel Myers' {' '}
                    <Link href="https://www.basketball-reference.com/about/bpm2.html" target="_blank" rel="noopener noreferrer" color="#8685EF" sx={{ textDecoration: 'none', '&:hover': { color: "#65FBD2", textDecoration: 'underline' } }}>
                        BPM
                    </Link>
                    {' '} using the Japanese B League data, thanks to their openly available methods. Recently, I got to a point where I was able to calculate RAPM from the raw play-by-play and have collected data back to the 2017-18 season. Now that I have a 7-year sample for the B League RAPM, I felt ready to create my own metric which was tailored specifically to the Japanese B League. To my knowledge, this will be the first all-in-one metric created, as well as the first Bayesian-RAPM model in the public domain for the B League.
                </Typography>
                <Typography variant="body1" paragraph>
                    The motivation of choosing the Bayesian-RAPM method is simply due to its reputation around the NBA. While there is no perfect all-in-one metric, the ones currently regarded as the best and the ones I like, mostly use this method. Guesstimated Plus-Minus, the name, is (obviously) inspired by EPM. While EPM's methodology does not state the variables used and I cannot replicate it due to the unavailability of tracking data anyway, GPM is most inspired by EPM's ideas.
                </Typography>
                <Divider style={{ margin: '20px 0' }} />
                <Typography variant="h5" component="h2" gutterBottom>
                    The Box Score Component
                </Typography>
                <Typography variant="body1" paragraph>
                    The box score component is calculated using simple per-100 possession box score stats. The coefficients were calculated using a 7-year RAPM sample for both offense and defense. The variable selection is similar to PIPM (the difference is that PIPM uses per-36 minute stats), however I purposely did not include data on how much a player started games, because in the Japanese League import players are usually the better players, but due to the restrictions on the number of import players both on-court and on the roster, good players can sometimes start on the bench.
                </Typography>
                <Typography variant="body1" paragraph>
                    The box score prior is also padded using Kostya Medvedovsky's method explained {' '}
                    <Link href="https://kmedved.com/2020/08/06/nba-stabilization-rates-and-the-padding-approach/" target="_blank" rel="noopener noreferrer" color="#8685EF" sx={{ textDecoration: 'none', '&:hover': { color: "#65FBD2", textDecoration: 'underline' } }}>
                        here
                    </Link>
                    . Each padding number is determined by differential evolution, using the same 7-year sample of the box score. By using this method, it stabilizes the box score component which would be crucial especially for small samples. The idea of padding the box score component was used in NBACouchside's {' '}
                    <Link href="https://nbacouchside.net/2022/11/05/introducing-nba-stable-player-impact-spi/" target="_blank" rel="noopener noreferrer" color="#8685EF" sx={{ textDecoration: 'none', '&:hover': { color: "#65FBD2", textDecoration: 'underline' } }}>
                        SPI
                    </Link>
                    . In my opinion, padding each per-100 possession stat with its optimal number is an improvement on previous metrics which padded replacement level possessions/minutes to the overall metric. This allows the box score component to capture good players with small samples relatively quickly, which is especially important for international leagues, because a very good player relative to average can come over from a different different league mid-season (For example Hyunjung Lee came over for the final 20 games of the B League because his NBL season ended early). This does not happen in the NBA where the best players in the world compete at the highest level.
                </Typography>
                <Typography variant="body1" paragraph>
                    After aggregating the padded box score stats into one number for each offense and defense, I apply a league-wide adjustment to make 0 the average value accounting for % of possessions played. This is similar to BPM's team adjustment, but done once for the entire league instead of team-wise. The decision to do this adjustment league-wide rather than each team is to follow the spirit of EPM, which is to intentionally not include team statistics, even if those team statistics improve the overall R2. GPM also does not use team ORTG/DRTG in any kind.
                </Typography>
                <Typography variant="body1" paragraph>
                    Below are the coefficients and padding numbers used in the calculation of the box score component.
                </Typography>
                <Typography variant="h5" component="h3" gutterBottom>
                    Padding Numbers
                </Typography>
                <StyledTableContainer>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell sx={{ fontWeight: 'bold' }}>Statistic</TableCell>
                                <TableCell sx={{ fontWeight: 'bold' }}>Padding Denominator</TableCell>
                                <TableCell sx={{ fontWeight: 'bold' }}>Padding Value</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {paddingNumber.map((row, index) => (
                                <TableRow key={index}>
                                    <TableCell component="th" scope="row">
                                        {row.variable}
                                    </TableCell>
                                    <TableCell>{row.paddingDenominator}</TableCell>
                                    <TableCell>{row.paddingValue}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </StyledTableContainer>
                <Typography variant="h5" component="h3" gutterBottom>
                    Coefficients
                </Typography>
                <StyledTableContainer>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell sx={{ fontWeight: 'bold' }}>Statistic</TableCell>
                                <TableCell sx={{ fontWeight: 'bold' }}>Offensive Coef.</TableCell>
                                <TableCell sx={{ fontWeight: 'bold' }}>Defense Coef.</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {coefficients.map((row, index) => (
                                <TableRow key={index}>
                                    <TableCell component="th" scope="row">
                                        {row.variable}
                                    </TableCell>
                                    <TableCell>{row.offensiveCoef}</TableCell>
                                    <TableCell>{row.defenseCoef}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </StyledTableContainer>
                <Divider style={{ margin: '20px 0' }} />
                <Typography variant="h5" component="h2" gutterBottom>
                    RAPM Calculation
                </Typography>
                <Typography variant="body1" paragraph>
                    The last step of this calculating GPM is the RAPM calculation. Normally, RAPM using single season data does not go well. However, the concept of Bayesian-RAPM is to feed a Bayesian prior to the RAPM model, which gives RAPM a better starting point rather than starting all players from 0. In GPM, I use the aforementioned box score component as the Bayesian prior and perform the single season RAPM calculation with it. By doing this single season RAPM calculation as the final step of GPM calculation, it is able to catch more complicated nuances than when using simple on/off stats where you do not take teammate performance in to account.
                </Typography>
                <Typography variant="body1" paragraph>
                    When calculating the single-season RAPM, lambda value in the Ridge regression needs to be carefully determined. While it can be optimized through cross-validation, I have found that using a higher lambda than what cross-validation suggests gives me better final results. This is likely because a single-season sample for RAPM calculation is not enough as a sample, and performing cross-validation within that small sample is not the best way to optimize the lambda value. Taylor Snarr also says he uses "a fairly strong lambda" for EPM, which moves the prior values usually within 1. In GPM, the RAPM component usually moves the prior values within 2.
                </Typography>
                <Typography variant="body1" paragraph>
                    The calculation was done using {' '}
                    <Link href="https://github.com/rd11490/NBA_Tutorials/tree/master/rapm_prior" target="_blank" rel="noopener noreferrer" color="#8685EF" sx={{ textDecoration: 'none', '&:hover': { color: "#65FBD2", textDecoration: 'underline' } }}>
                        Ryan Davis' tutorial
                    </Link>
                    {' '} which was publicly available on GitHub, and was also recommended by the team responsible for LEBRON. Without this guidance, the coding process would be much longer.
                </Typography>
                <Typography variant="body1" paragraph>
                    You can find the Guesstimated Plus-Minus values from the latest season (and past seasons) {' '}
                    <Link href="/gpm/" target="_blank" rel="noopener noreferrer" color="#8685EF" sx={{ textDecoration: 'none', '&:hover': { color: "#65FBD2", textDecoration: 'underline' } }}>
                        here
                    </Link>
                    .
                </Typography>
                <Typography variant="body1" paragraph>
                    Here is a diagram on the steps necessary for GPM calculation:
                </Typography>
                <img src={diagramImage} alt="Diagram" style={{ width: '100%', marginBottom: theme.spacing(2) }} />
            </Container>
        </ThemeProvider>
    );
};

export default GPMExplanationEnglish;