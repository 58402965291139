// Dropdowns.js
import React from 'react';
import { FormControl, InputLabel, Select, MenuItem } from '@mui/material';

export const SeasonDropdown = ({ currentSeasonId, setSeasonId, seasons, className }) => {
    const handleSeasonChange = (event) => {
        const newSeasonId = event.target.value;
        setSeasonId(newSeasonId);
    };

    return (
        <FormControl className={className} variant="outlined" sx={{ m: 1, minWidth: 120 }}>
            <InputLabel>SEASON</InputLabel>
            <Select value={currentSeasonId} onChange={handleSeasonChange} label="Season">
                {seasons.map((season) => (
                    <MenuItem key={season} value={season}>
                        {`${season.slice(0, 4)}-${season.slice(4)}`}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
};

export const LeagueDropdown = ({ selectedLeague, setLeagueId, className }) => {
    const handleLeagueChange = (event) => {
        setLeagueId(event.target.value);
    };

    const leagues = [
        { id: '1', name: 'B1' },
        { id: '2', name: 'B2' }
    ];

    return (
        <FormControl className={className} variant="outlined" sx={{ m: 1, minWidth: 100 }}>
            <InputLabel>LEAGUE</InputLabel>
            <Select
                value={selectedLeague}
                onChange={handleLeagueChange}
                label="League"
            >
                {leagues.map((league) => (
                    <MenuItem key={league.id} value={league.id}>
                        {league.name}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
};

export const TeamDropdown = ({ teamFilter, setTeamFilter, uniqueTeams, showJapanese, className }) => (
    <FormControl className={className} variant="outlined" sx={{ m: 1, minWidth: 150 }}>
        <InputLabel>TEAM</InputLabel>
        <Select
            value={teamFilter || []}
            onChange={(event) => setTeamFilter(event.target.value)}
            label="Team"
            multiple
        >
            {uniqueTeams.map((team) => (
                <MenuItem key={team} value={team}>{team}</MenuItem>
            ))}
        </Select>
    </FormControl>
);

export const PositionDropdown = ({ positionFilter, setPositionFilter, orderedPositions, className }) => (
    <FormControl className={className} variant="outlined" sx={{ m: 1, minWidth: 150 }}>
        <InputLabel>POSITION</InputLabel>
        <Select
            value={positionFilter || []}
            onChange={(event) => setPositionFilter(event.target.value)}
            label="Position"
            multiple
        >
            {orderedPositions.map((position) => (
                <MenuItem key={position} value={position}>{position}</MenuItem>
            ))}
        </Select>
    </FormControl>
);

export const RosterTypeDropdown = ({ playerClassFilter, setPlayerClassFilter, rosterTypeMapping, showJapanese, className }) => (
    <FormControl className={className} variant="outlined" sx={{ m: 1, minWidth: 250 }}>
        <InputLabel>ROSTER TYPE</InputLabel>
        <Select
            value={playerClassFilter || []}
            onChange={(event) => setPlayerClassFilter(event.target.value)}
            label="Player Class"
            multiple
        >
            {Object.entries(rosterTypeMapping).map(([key, value]) => (
                <MenuItem key={key} value={parseInt(key)}>
                    {showJapanese ? value.jp : value.en}
                </MenuItem>
            ))}
        </Select>
    </FormControl>
);

export const CategoryDropdown = ({ category, setCategory, className }) => {
    const categories = [
        { value: 'all', label: 'All Shots' },
        { value: 'rim', label: 'Rim' },
        { value: 'floater', label: 'Floater' },
        { value: 'midrange', label: 'Midrange' },
        { value: 'three_atb', label: 'Above the Break 3' },
        { value: 'three_corner', label: 'Corner 3' }
    ];

    const handleCategoryChange = (event) => {
        setCategory(event.target.value);
    };

    return (
        <FormControl className={className} variant="outlined" sx={{ m: 1, minWidth: 150 }}>
            <InputLabel>CATEGORY</InputLabel>
            <Select
                value={category}
                onChange={handleCategoryChange}
                label="Category"
            >
                {categories.map((cat) => (
                    <MenuItem key={cat.value} value={cat.value}>
                        {cat.label}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
};