import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Switch, TableSortLabel, Box, Select, MenuItem, FormControl, InputLabel } from '@mui/material';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import { motion, AnimatePresence } from 'framer-motion';
import { SeasonDropdown, LeagueDropdown, CategoryDropdown } from './Dropdowns';
import LoadingAnimation from './LoadingAnimation';
import './ImpactMetricsTable.css';
import PageTransition from './PageTransition';
import { seasonsShotData } from './constants';
import ColoredCell, { calculatePercentiles} from './ColoringLogic';
import { getComparator } from './SortingLogic';

const baseUrl = process.env.REACT_APP_API_URL;

const TeamShotQuality = () => {
    const [seasonId, setSeasonId] = useState('202324');
    const [leagueId, setLeagueId] = useState('1');
    const [category, setCategory] = useState('all');
    const [ownTeam, setOwnTeam] = useState('True');

    const [data, setData] = useState([]);
    const [error, setError] = useState(null);
    const [percentiles, setPercentiles] = useState({});
    const [orderDirection, setOrderDirection] = useState('desc');
    const [orderBy, setOrderBy] = useState('qsi');

    const [showJapanese, setShowJapanese] = useState(true);
    const [divisionFilter, setDivisionFilter] = useState({ japanese: '', english: '' });
    const [divisionOptions, setDivisionOptions] = useState([]);

    const [isLoading, setIsLoading] = useState(false);

    const responsiveStyle = window.innerWidth <= 600 ? { fontSize: '11px' } : {};
    const responsiveCellStyle = window.innerWidth <= 600 ? { padding: '2px', minWidth: '30px' } : { padding: '2px', minWidth: '80px' };
    const responsiveRowStyle = window.innerWidth <= 600 ? { padding: '0px', minHeight: '10px' } : { padding: '0px', minHeight: '10px' };

    useEffect(() => {
        // Reverse the order when 'OPP TEAM' is selected
        setOrderDirection(ownTeam === 'True' ? 'desc' : 'asc');
    }, [ownTeam]);

    useEffect(() => {
        setIsLoading(true);
        axios.get(`${baseUrl}/api/team-shot-quality/?season_id=${seasonId}&league_id=${leagueId}&category=${category}&own_team=${ownTeam}`)
            .then(response => {
                setData(response.data);
                setPercentiles(calculatePercentiles(response.data, ['frequency', 'pts', 'xpts', 'points_added', 'fg_percent', 'efg', 'qsq', 'qsi']));
                updateDivisionOptions(response.data);
                setIsLoading(false);
            })
            .catch(error => {
                console.error("Failed to fetch shot quality data", error);
                setError("Failed to fetch data");
                setIsLoading(false);
            });
    }, [seasonId, leagueId, category, ownTeam]);

    const updateDivisionOptions = (data) => {
        const divisions = data.map(team => ({
            japanese: team.division,
            english: team.division_eng
        }));
        const uniqueDivisions = Array.from(new Set(divisions.map(JSON.stringify)), JSON.parse);
        setDivisionOptions(uniqueDivisions);
    };

    const handleLanguageToggle = () => {
        setShowJapanese(!showJapanese);
    };

    const commonCellStyle = {
        transition: 'all 0.2s ease',
    };

    const handleSortRequest = (columnId) => {
        const isAsc = orderBy === columnId && orderDirection === 'desc';
        setOrderDirection(isAsc ? 'asc' : 'desc');
        setOrderBy(columnId);
    };

    const sortedAndFilteredData = data
        .filter(team => {
            return showJapanese
                ? (!divisionFilter.japanese || team.division === divisionFilter.japanese)
                : (!divisionFilter.english || team.division_eng === divisionFilter.english);
        })
        .sort(getComparator(orderDirection, orderBy));

    const handleDivisionFilterChange = (event) => {
        const selectedValue = event.target.value;
        const selectedDivision = divisionOptions.find(division =>
            division.japanese === selectedValue || division.english === selectedValue);

        if (selectedDivision) {
            setDivisionFilter(selectedDivision);
        } else {
            setDivisionFilter({ japanese: '', english: '' });
        }
    };

    const handleOwnTeamChange = (event) => {
        setOwnTeam(event.target.value);
    };

    return (
        <PageTransition>
            <Container maxWidth="lg" sx={{ my: 10 }}>
                <Typography variant="h4" gutterBottom className="impact-metrics-title">
                    B.LEAGUE <span className="impact-metrics-break">Team Shooting Statistics</span>
                </Typography>
                <Box
                    sx={{
                        display: "flex",
                        flexWrap: { xs: 'wrap', sm: 'nowrap' }, // Wrap on extra-small screens, no wrap on small screens and up
                        alignItems: "center",
                        overflowX: 'auto' // Horizontal scroll on overflow
                    }}
                >
                    <SeasonDropdown
                        currentSeasonId={seasonId}
                        setSeasonId={setSeasonId}
                        seasons={seasonsShotData}
                        className="impact-metrics-formControl"
                    />
                    <LeagueDropdown
                        selectedLeague={leagueId}
                        setLeagueId={setLeagueId}
                        className="impact-metrics-formControl"
                    />
                    <CategoryDropdown
                        category={category}
                        setCategory={setCategory}
                        className="impact-metrics-formControl"
                    />
                    <FormControl variant="outlined" sx={{ minWidth: 150 }}>
                        <InputLabel>Team Type</InputLabel>
                        <Select
                            value={ownTeam}
                            onChange={handleOwnTeamChange}
                            label="Team Type"
                        >
                            <MenuItem value="True">OWN TEAM</MenuItem>
                            <MenuItem value="False">OPPONENT</MenuItem>
                        </Select>
                    </FormControl>
                    <FormControl className="team-stats-formControl" variant="outlined" sx={{ m: 1, minWidth: 150 }}>
                        <InputLabel>Division</InputLabel>
                        <Select
                            value={showJapanese ? divisionFilter.japanese : divisionFilter.english}
                            onChange={handleDivisionFilterChange}
                            label="Division"
                        >
                            <MenuItem value="">
                                <em>All</em>
                            </MenuItem>
                            {divisionOptions.map((division, index) => (
                                <MenuItem key={index} value={showJapanese ? division.japanese : division.english}>
                                    {showJapanese ? division.japanese : division.english}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Box>
                <Box
                    sx={{
                        display: "flex",
                        flexWrap: { xs: 'wrap', sm: 'nowrap' },
                        alignItems: "center",
                        overflowX: 'auto'
                    }}
                >
                    <Typography variant="body2" sx={{ mr: 1 }}>
                        English
                    </Typography>
                    <Switch
                        checked={!showJapanese}
                        onChange={handleLanguageToggle} // Updated handler
                        name="languageToggle"
                        inputProps={{ 'aria-label': 'language toggle' }}
                    />
                </Box>
                <div style={{ overflowX: 'auto' }}>
                    <TableContainer>
                        {error && <p>{error}</p>}
                        <AnimatePresence mode="wait">
                            {isLoading ? (
                                <motion.div
                                    key="loading"
                                    initial={{ opacity: 0 }}
                                    animate={{ opacity: 1 }}
                                    exit={{ opacity: 0 }}
                                    transition={{ duration: 0.3 }}
                                >
                                    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100px' }}>
                                        <LoadingAnimation />
                                    </Box>
                                </motion.div>
                            ) : (
                                <motion.div
                                    key="table"
                                    initial={{ opacity: 0 }}
                                    animate={{ opacity: 1 }}
                                    exit={{ opacity: 0 }}
                                    transition={{ duration: 0.5 }}
                                >
                                    <Table className="table-auto w-full" aria-label="Impact Metrics Table">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell component="th" style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', ...responsiveStyle, ...responsiveCellStyle }}>
                                                    <TableSortLabel
                                                        active={false}
                                                        direction={'asc'}
                                                    >
                                                        Rank
                                                    </TableSortLabel>
                                                </TableCell>
                                                <TableCell
                                                    component="th"
                                                    className="sticky left-0 z-10 bg-white responsive-table-header"
                                                >
                                                    <Typography
                                                        variant="body1"
                                                        sx={{
                                                            whiteSpace: 'nowrap',
                                                            overflow: 'hidden',
                                                            textOverflow: 'ellipsis',
                                                            minWidth: '120px', // Ensure you use string with px for inline styles in MUI sx prop
                                                            position: 'sticky',
                                                            left: 0,
                                                            zIndex: 10, // Ensure zIndex is high enough to be on top of other elements
                                                        }}
                                                        style={{ ...responsiveStyle, ...responsiveCellStyle }}
                                                    >
                                                        Team
                                                    </Typography>
                                                </TableCell>
                                                <TableCell
                                                    component="th"
                                                    style={{
                                                        whiteSpace: 'nowrap',
                                                        overflow: 'hidden',
                                                        textOverflow: 'ellipsis',
                                                        textAlign: 'center', // Add this line
                                                        ...responsiveStyle,
                                                        ...responsiveCellStyle
                                                    }}
                                                >
                                                    <TableSortLabel
                                                        active={orderBy === 'fga'}
                                                        direction={orderBy === 'fga' ? orderDirection : 'desc'}
                                                        onClick={() => handleSortRequest('fga')}
                                                    >
                                                        FGA
                                                    </TableSortLabel>
                                                </TableCell>
                                                <TableCell
                                                    component="th"
                                                    style={{
                                                        whiteSpace: 'nowrap',
                                                        overflow: 'hidden',
                                                        textOverflow: 'ellipsis',
                                                        textAlign: 'center', // Add this line
                                                        ...responsiveStyle,
                                                        ...responsiveCellStyle
                                                    }}
                                                >
                                                    <TableSortLabel
                                                        active={orderBy === 'frequency'}
                                                        direction={orderBy === 'frequency' ? orderDirection : 'desc'}
                                                        onClick={() => handleSortRequest('frequency')}
                                                    >
                                                        FREQ%
                                                    </TableSortLabel>
                                                </TableCell>
                                                <TableCell
                                                    component="th"
                                                    style={{
                                                        whiteSpace: 'nowrap',
                                                        overflow: 'hidden',
                                                        textOverflow: 'ellipsis',
                                                        textAlign: 'center', // Add this line
                                                        ...responsiveStyle,
                                                        ...responsiveCellStyle
                                                    }}
                                                >
                                                    <TableSortLabel
                                                        active={orderBy === 'pts'}
                                                        direction={orderBy === 'pts' ? orderDirection : 'desc'}
                                                        onClick={() => handleSortRequest('pts')}
                                                    >
                                                        PTS
                                                    </TableSortLabel>
                                                </TableCell>
                                                <TableCell
                                                    component="th"
                                                    style={{
                                                        whiteSpace: 'nowrap',
                                                        overflow: 'hidden',
                                                        textOverflow: 'ellipsis',
                                                        textAlign: 'center', // Add this line
                                                        ...responsiveStyle,
                                                        ...responsiveCellStyle
                                                    }}
                                                >
                                                    <TableSortLabel
                                                        active={orderBy === 'xpts'}
                                                        direction={orderBy === 'xpts' ? orderDirection : 'desc'}
                                                        onClick={() => handleSortRequest('xpts')}
                                                    >
                                                        xPTS
                                                    </TableSortLabel>
                                                </TableCell>
                                                <TableCell
                                                  component="th"
                                                  style={{
                                                    whiteSpace: 'nowrap',
                                                    overflow: 'hidden',
                                                    textOverflow: 'ellipsis',
                                                    textAlign: 'center',
                                                    ...responsiveStyle,
                                                    ...responsiveCellStyle
                                                  }}
                                                >
                                                  <TableSortLabel
                                                    active={orderBy === 'points_added'}
                                                    direction={orderBy === 'points_added' ? orderDirection : 'desc'}
                                                    onClick={() => handleSortRequest('points_added')}
                                                    style={{ whiteSpace: 'pre-wrap' }} // Add this style
                                                  >
                                                    Points
                                                    <br />
                                                    Added
                                                  </TableSortLabel>
                                                </TableCell>
                                                <TableCell
                                                    component="th"
                                                    style={{
                                                        whiteSpace: 'nowrap',
                                                        overflow: 'hidden',
                                                        textOverflow: 'ellipsis',
                                                        textAlign: 'center', // Add this line
                                                        ...responsiveStyle,
                                                        ...responsiveCellStyle
                                                    }}
                                                >
                                                    <TableSortLabel
                                                        active={orderBy === 'fg_percent'}
                                                        direction={orderBy === 'fg_percent' ? orderDirection : 'desc'}
                                                        onClick={() => handleSortRequest('fg_percent')}
                                                    >
                                                        FG%
                                                    </TableSortLabel>
                                                </TableCell>
                                                <TableCell
                                                    component="th"
                                                    style={{
                                                        whiteSpace: 'nowrap',
                                                        overflow: 'hidden',
                                                        textOverflow: 'ellipsis',
                                                        textAlign: 'center', // Add this line
                                                        ...responsiveStyle,
                                                        ...responsiveCellStyle
                                                    }}
                                                >
                                                    <TableSortLabel
                                                        active={orderBy === 'efg'}
                                                        direction={orderBy === 'efg' ? orderDirection : 'desc'}
                                                        onClick={() => handleSortRequest('efg')}
                                                    >
                                                        eFG%
                                                    </TableSortLabel>
                                                </TableCell>
                                                <TableCell
                                                    component="th"
                                                    style={{
                                                        whiteSpace: 'nowrap',
                                                        overflow: 'hidden',
                                                        textOverflow: 'ellipsis',
                                                        textAlign: 'center', // Add this line
                                                        ...responsiveStyle,
                                                        ...responsiveCellStyle
                                                    }}
                                                >
                                                    <TableSortLabel
                                                        active={orderBy === 'qsq'}
                                                        direction={orderBy === 'qsq' ? orderDirection : 'desc'}
                                                        onClick={() => handleSortRequest('qsq')}
                                                    >
                                                        qSQ
                                                    </TableSortLabel>
                                                </TableCell>
                                                <TableCell
                                                    component="th"
                                                    style={{
                                                        whiteSpace: 'nowrap',
                                                        overflow: 'hidden',
                                                        textOverflow: 'ellipsis',
                                                        textAlign: 'center', // Add this line
                                                        ...responsiveStyle,
                                                        ...responsiveCellStyle
                                                    }}
                                                >
                                                    <TableSortLabel
                                                        active={orderBy === 'qsi'}
                                                        direction={orderBy === 'qsi' ? orderDirection : 'desc'}
                                                        onClick={() => handleSortRequest('qsi')}
                                                    >
                                                        qSI
                                                    </TableSortLabel>
                                                </TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {sortedAndFilteredData.map((team, index) => (
                                                <TableRow
                                                    key={`${team.team_id}`}
                                                    sx={{
                                                        '& > *': { py: '2px' },
                                                        '&:hover': {
                                                            '& .MuiTableCell-root:not(.player-name-cell)': {
                                                                filter: 'brightness(90%)',
                                                            },
                                                            '& .player-name-cell': {
                                                                backgroundColor: 'rgb(240, 238, 245)',
                                                            },
                                                        },
                                                    }}
                                                >
                                                    <TableCell className="left-column" sx={commonCellStyle} style={responsiveStyle}>{index + 1}</TableCell>
                                                    <TableCell
                                                        component="th"
                                                        scope="row"
                                                        className="sticky left-0 z-10 player-name-cell"
                                                        sx={{
                                                            ...commonCellStyle,
                                                            backgroundColor: 'rgb(250, 248, 255)',
                                                            position: 'sticky',
                                                            left: 0,
                                                            zIndex: 10,
                                                        }}
                                                    >
                                                        <Typography
                                                            variant="body1"
                                                            sx={{
                                                                color: 'black',
                                                                fontSize: '0.875rem',
                                                                whiteSpace: 'nowrap',
                                                                overflow: 'hidden',
                                                                textOverflow: 'ellipsis',
                                                                maxWidth: '100%'
                                                            }}
                                                            style={{ ...responsiveStyle, ...responsiveRowStyle }}
                                                        >
                                                            {showJapanese ? team.team_name : team.team_name_eng}
                                                        </Typography>
                                                        <Typography
                                                            variant="body2"
                                                            sx={{
                                                                color: 'grey',
                                                                fontSize: '0.75rem',
                                                                whiteSpace: 'nowrap',
                                                                overflow: 'hidden',
                                                                textOverflow: 'ellipsis',
                                                                maxWidth: '100%'
                                                            }}
                                                            style={{ ...responsiveStyle, ...responsiveRowStyle }}
                                                        >
                                                            {showJapanese ? team.division : team.division_eng}
                                                        </Typography>
                                                    </TableCell>
                                                    <TableCell
                                                        className="left-column"
                                                        sx={{
                                                            ...commonCellStyle,
                                                            textAlign: 'center'
                                                        }}
                                                        style={responsiveStyle}
                                                    >
                                                        {team.fga}
                                                    </TableCell>
                                                    <ColoredCell
                                                        value={team.frequency}
                                                        percentile={percentiles['frequency']?.[team.frequency]}
                                                        format={(value) => `${(value * 100).toFixed(1)}%`}
                                                        sx={commonCellStyle}
                                                    />
                                                    <ColoredCell
                                                        value={team.pts}
                                                        percentile={percentiles['pts']?.[team.pts]}
                                                        format={value => `${value}`}
                                                        sx={commonCellStyle}
                                                        reverse={ownTeam === 'False'}
                                                    />
                                                    <ColoredCell
                                                        value={team.xpts}
                                                        percentile={percentiles['xpts']?.[team.xpts]}
                                                        format={value => `${value.toFixed(1)}`}
                                                        sx={commonCellStyle}
                                                        reverse={ownTeam === 'False'}
                                                    />
                                                    <ColoredCell
                                                        value={team.points_added}
                                                        percentile={percentiles['points_added']?.[team.points_added]}
                                                        format={value => `${value.toFixed(1)}`}
                                                        sx={commonCellStyle}
                                                        reverse={ownTeam === 'False'}
                                                    />
                                                    <ColoredCell
                                                        value={team.fg_percent}
                                                        percentile={percentiles['fg_percent']?.[team.fg_percent]}
                                                        format={(value) => `${(value * 100).toFixed(1)}%`}
                                                        sx={commonCellStyle}
                                                        reverse={ownTeam === 'False'}
                                                    />
                                                    <ColoredCell
                                                        value={team.efg}
                                                        percentile={percentiles['efg']?.[team.efg]}
                                                        format={(value) => `${(value * 100).toFixed(1)}%`}
                                                        sx={commonCellStyle}
                                                        reverse={ownTeam === 'False'}
                                                    />
                                                    <ColoredCell
                                                        value={team.qsq}
                                                        percentile={percentiles['qsq']?.[team.qsq]}
                                                        format={(value) => `${(value * 100).toFixed(1)}%`}
                                                        sx={commonCellStyle}
                                                        reverse={ownTeam === 'False'}
                                                    />
                                                    <ColoredCell
                                                        value={team.qsi}
                                                        percentile={percentiles['qsi']?.[team.qsi]}
                                                        format={value => value > 0 ? `+${(value * 100).toFixed(1)}%` : `${(value * 100).toFixed(2)}%`}
                                                        sx={commonCellStyle}
                                                        reverse={ownTeam === 'False'}
                                                    />
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </motion.div>
                            )}
                        </AnimatePresence>
                    </TableContainer>
                </div>
            </Container>
        </PageTransition>
    );
};
export default TeamShotQuality;