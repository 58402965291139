// FetchButton.js
import React from 'react';
import { Button } from '@mui/material';

const buttonStyle = {
    bgcolor: '#65FBD2',
    color: '#38292C',
    px: 2, // theme.spacing(2) equivalent to 'px-6'
    py: 1, // theme.spacing(1) equivalent to 'py-3'
    '&:hover': {
        bgcolor: '#ABABAB',
    },
    textTransform: 'none', // If you don't want to capitalize button text
    borderRadius: '4px', // theme.shape.borderRadius equivalent to 'rounded'
    transition: 'background-color 0.3s' // equivalent to 'transition duration-300'
};

const FetchButton = ({ onClick }) => (
    <Button variant="contained" onClick={onClick} sx={buttonStyle}>Fetch WOWY Data</Button>
);

export default FetchButton;
