import React, { useState, useEffect, useRef } from 'react';
import { Box, Typography, Grid, Paper, Tab, Tabs, CircularProgress } from '@mui/material';
import { styled } from '@mui/material/styles';
import { gsap } from 'gsap';

const baseUrl = process.env.REACT_APP_API_URL;

const StyledPaper = styled(Paper)(({ theme }) => ({
    padding: theme.spacing(3),
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: '#38292C',
    color: '#FAF8FF',
    boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
    borderRadius: '12px',
    transition: 'transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out',
    '&:hover': {
        transform: 'translateY(-5px)',
        boxShadow: '0 6px 12px rgba(0, 0, 0, 0.15)',
    },
    overflow: 'hidden', // This ensures content doesn't overflow
}));

const LeagueSummary = () => {
    const [value, setValue] = useState(0);
    const [data, setData] = useState(null);
    const [lastUpdated, setLastUpdated] = useState(null);
    const contentRef = useRef(null);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const [leagueAvgResponse, lastUpdateResponse] = await Promise.all([
                    fetch(`${baseUrl}/api/league-avg/`),
                    fetch(`${baseUrl}/api/latest-update/`)
                ]);
                const leagueAvgData = await leagueAvgResponse.json();
                const lastUpdateData = await lastUpdateResponse.json();

                setData(leagueAvgData);
                setLastUpdated(lastUpdateData.updated_at);

                gsap.from('.league-summary', {
                    opacity: 0,
                    y: 50,
                    duration: 1,
                    ease: "power3.out",
                    stagger: 0.2
                });
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };

        fetchData();
    }, []);

    const handleChange = (event, newValue) => {
        setValue(newValue);

        // Animation for switching between B1 and B2
        gsap.fromTo(contentRef.current,
            { opacity: 0, y: 20 },
            { opacity: 1, y: 0, duration: 0.5, ease: "power2.out" }
        );
    };

    if (!data) {
        return <CircularProgress />;
    }

    const currentLeagueData = data[value === 0 ? '1' : '2'];

    const formatStat = (value, type) => {
        if (typeof value !== 'number') return 'N/A';

        if (type === 'player') {
            return value >= 0 ? `+${value.toFixed(2)}` : value.toFixed(2);
        } else {
            return value.toFixed(2);
        }
    };

    const renderRankedList = (data, type, statKey) => {
        return data.slice(0, 5).map((item, index) => (
            <Box key={index} sx={{ display: 'flex', justifyContent: 'space-between', marginBottom: '10px', alignItems: 'center' }}>
                <Box sx={{ flexGrow: 1, paddingRight: 2 }}>
                    <Typography>
                        {index + 1}. {type === 'player' ? item.player_name : item.team_name}
                    </Typography>
                    <Typography variant="caption" sx={{ color: '#ABABAB', display: 'block', fontSize: '0.7rem' }}>
                        {type === 'player' ? item.player_name_eng : item.team_name_eng}
                    </Typography>
                </Box>
                <Typography fontWeight="bold">
                    {formatStat(item[statKey], type)}
                </Typography>
            </Box>
        ));
    };

    if (!data) {
        return <CircularProgress />;
    }

    return (
        <Box className="league-summary" sx={{ maxWidth: '1200px', margin: '0 auto', padding: '40px 20px' }}>
            <Tabs
                value={value}
                onChange={handleChange}
                centered
                sx={{
                    marginBottom: '40px',
                    '& .MuiTab-root': {
                        color: '#ABABAB',
                        '&.Mui-selected': {
                            color: '#65FBD2',
                        },
                    },
                    '& .MuiTabs-indicator': {
                        backgroundColor: '#65FBD2',
                    },
                }}
            >
                <Tab label="B1" />
                <Tab label="B2" />
            </Tabs>
            <Box ref={contentRef}>
                <Grid container spacing={4}>
                    <Grid item xs={12} md={6}>
                        <StyledPaper>
                            <Typography variant="h6" gutterBottom>Top Offenses (ORTG)</Typography>
                            {renderRankedList(currentLeagueData.top_offenses, 'team', 'offensive_rating')}
                        </StyledPaper>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <StyledPaper>
                            <Typography variant="h6" gutterBottom>Top Defenses (DRTG)</Typography>
                            {renderRankedList(currentLeagueData.top_defenses, 'team', 'defensive_rating')}
                        </StyledPaper>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <StyledPaper>
                            <Typography variant="h6" gutterBottom>Top Players (PIPM)</Typography>
                            {renderRankedList(currentLeagueData.top_pipm_players, 'player', 'pipm')}
                        </StyledPaper>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <StyledPaper>
                            <Typography variant="h6" gutterBottom>Top Players (BPM)</Typography>
                            {renderRankedList(currentLeagueData.top_bpm_players, 'player', 'bpm')}
                        </StyledPaper>
                    </Grid>
                </Grid>
            </Box>
            {lastUpdated && (
                <Typography variant="caption" sx={{ display: 'block', textAlign: 'center', marginTop: '20px', color: '#ABABAB' }}>
                    Last updated: {new Date(lastUpdated).toLocaleDateString()}
                </Typography>
            )}
        </Box>
    );
};

export default LeagueSummary;