import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Switch } from '@mui/material';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import { TableSortLabel } from '@mui/material';
import { FormControl } from '@mui/material';
import { Slider, Box } from '@mui/material';
import { motion, AnimatePresence } from 'framer-motion';
import { SeasonDropdown, LeagueDropdown, TeamDropdown, PositionDropdown, RosterTypeDropdown } from './Dropdowns';
import LoadingAnimation from './LoadingAnimation';
import './ImpactMetricsTable.css';
import PageTransition from './PageTransition';
import { seasons, rosterTypeMapping, positionOrder, impactMetrics } from './constants';
import ColoredCell, { calculatePercentiles } from './ColoringLogic';
import { sortData, getComparator } from './SortingLogic';
import { Link } from 'react-router-dom';

const baseUrl = process.env.REACT_APP_API_URL;

const ImpactMetricsTable = () => {
    const [seasonId, setSeasonId] = useState('202324');
    const [leagueId, setLeagueId] = useState('1');
    const [data, setData] = useState([]);
    const [error, setError] = useState(null);
    const [percentiles, setPercentiles] = useState({});
    const [orderDirection, setOrderDirection] = useState('desc');
    const [orderBy, setOrderBy] = useState('pipm');
    const [teamFilter, setTeamFilter] = useState([]);
    const [positionFilter, setPositionFilter] = useState([]);
    const [playerClassFilter, setPlayerClassFilter] = useState([]);
    const [minFilter, setMinFilter] = useState(100);
    const [appliedMinFilter, setAppliedMinFilter] = useState(100);
    const [maxMinutes, setMaxMinutes] = useState(2000);
    const [showJapanese, setShowJapanese] = useState(true);
    const [uniqueTeamsJP, setUniqueTeamsJP] = useState([]);
    const [uniqueTeamsEN, setUniqueTeamsEN] = useState([]);
    const [teamNameMapping, setTeamNameMapping] = useState({});
    const [isLoading, setIsLoading] = useState(false);

    // Responsive styles
    const responsiveStyle = window.innerWidth <= 600 ? { fontSize: '11px' } : {};
    const responsiveCellStyle = window.innerWidth <= 600 ? { padding: '2px', minWidth: '30px' } : { padding: '2px', minWidth: '80px' };
    const responsiveRowStyle = window.innerWidth <= 600 ? { padding: '0px', minHeight: '10px' } : { padding: '0px', minHeight: '10px' };

    useEffect(() => {
        setIsLoading(true);
        axios.get(`${baseUrl}/api/impact_metrics/?season_id=${seasonId}&league_id=${leagueId}`)
            .then(response => {
                setData(response.data);
                setMaxMinutes(Math.max(...response.data.map(player => player.min_played)));
                setPercentiles(calculatePercentiles(response.data, impactMetrics));
                const mapping = response.data.reduce((acc, player) => {
                    acc[player.team_name] = player.team_name_eng;
                    return acc;
                }, {});
                setTeamNameMapping(mapping);
                setUniqueTeamsJP(getUniqueTeams(response.data, 'team_name', mapping));
                setUniqueTeamsEN(getUniqueTeams(response.data, 'team_name_eng', mapping));
                setIsLoading(false);
            })
            .catch(error => {
                console.error("Failed to fetch impact metrics data", error);
                setError("Failed to fetch data");
                setIsLoading(false);
            });
    }, [seasonId, leagueId]);

    const getUniqueTeams = (data, teamProp, teamNameMapping) => {
        const uniqueTeams = [...new Set(data.map(player => player[teamProp]))].sort((a, b) => {
            return teamNameMapping[a]?.team_id - teamNameMapping[b]?.team_id;
        });
        return uniqueTeams;
    };

    const handleLanguageToggle = () => {
        setShowJapanese(!showJapanese);
        if (teamFilter.length) {
            setTeamFilter(showJapanese
                ? teamFilter.map(team => teamNameMapping[team] || team)
                : teamFilter.map(team => Object.keys(teamNameMapping).find(key => teamNameMapping[key] === team) || team)
            );
        }
    };

    const commonCellStyle = {
        transition: 'all 0.2s ease',
    };

    const handleSortRequest = (columnId) => {
        const isAsc = orderBy === columnId && orderDirection === 'desc';
        setOrderDirection(isAsc ? 'asc' : 'desc');
        setOrderBy(columnId);
    };

    const handleSliderChangeCommitted = (event, newValue) => {
        setAppliedMinFilter(newValue);
    };

    const filteredData = data.filter((player) => {
        const playerTeams = showJapanese ? [player.team_name] : [player.team_name_eng];
        const playerPositions = player.position.split('/');

        return (
            (!teamFilter.length || teamFilter.some(team => playerTeams.includes(team))) &&
            (!positionFilter.length || positionFilter.some(position => playerPositions.includes(position))) &&
            (!playerClassFilter || playerClassFilter.length === 0 || playerClassFilter.includes(player.player_class)) &&
            (parseInt(player.min_played, 10) >= appliedMinFilter)
        );
    });

    const sortedAndFilteredData = sortData(filteredData, getComparator(orderDirection, orderBy));

    const uniquePositions = [...new Set(data.flatMap((player) => player.position.split('/')))];
    const orderedPositions = positionOrder.filter(position => uniquePositions.includes(position));

    return (
        <PageTransition>
            <Container maxWidth="lg" sx={{ my: 10 }}>
                <Typography variant="h4" gutterBottom className="impact-metrics-title">
                    B.LEAGUE <span className="impact-metrics-break">Impact Metrics</span>
                </Typography>
                <Box
                    sx={{
                        display: "flex",
                        flexWrap: { xs: 'wrap', sm: 'nowrap' }, // Wrap on extra-small screens, no wrap on small screens and up
                        alignItems: "center",
                        overflowX: 'auto' // Horizontal scroll on overflow
                    }}
                >
                    <SeasonDropdown
                        currentSeasonId={seasonId}
                        setSeasonId={setSeasonId}
                        seasons={seasons}
                        className="impact-metrics-formControl"
                    />
                    <LeagueDropdown
                        selectedLeague={leagueId}
                        setLeagueId={setLeagueId}
                        className="impact-metrics-formControl"
                    />
                    <TeamDropdown
                        teamFilter={teamFilter}
                        setTeamFilter={setTeamFilter}
                        uniqueTeams={showJapanese ? uniqueTeamsJP : uniqueTeamsEN}
                        showJapanese={showJapanese}
                        className="impact-metrics-formControl"
                    />
                    <PositionDropdown
                        positionFilter={positionFilter}
                        setPositionFilter={setPositionFilter}
                        orderedPositions={orderedPositions}
                        className="impact-metrics-formControl"
                    />
                    <RosterTypeDropdown
                        playerClassFilter={playerClassFilter}
                        setPlayerClassFilter={setPlayerClassFilter}
                        rosterTypeMapping={rosterTypeMapping}
                        showJapanese={showJapanese}
                        className="impact-metrics-formControl"
                    />
                    <FormControl className="impact-metrics-slider" variant="outlined" sx={{ m: 1, minWidth: 200, height: 56, display: 'flex', flexDirection: 'row', alignItems: 'center', border: '1px solid #c4c4c4', borderRadius: '4px', p: 1 }}>
                        <Typography sx={{ mr: 2 }} variant="body2">MIN</Typography>
                        <Slider
                            value={minFilter}
                            onChange={(event, newValue) => setMinFilter(newValue)}
                            onChangeCommitted={handleSliderChangeCommitted}
                            aria-labelledby="min-slider"
                            valueLabelDisplay="auto"
                            min={0}
                            max={parseInt(maxMinutes, 10)}
                            sx={{ flexGrow: 1 }}
                        />
                    </FormControl>
                </Box>
                <Box
                    sx={{
                        display: "flex",
                        flexWrap: { xs: 'wrap', sm: 'nowrap' },
                        alignItems: "center",
                        overflowX: 'auto'
                    }}
                >
                    <Typography variant="body2" sx={{ mr: 1 }}>
                        English
                    </Typography>
                    <Switch
                        checked={!showJapanese}
                        onChange={handleLanguageToggle} // Updated handler
                        name="languageToggle"
                        inputProps={{ 'aria-label': 'language toggle' }}
                    />
                </Box>
                <div style={{ overflowX: 'auto' }}>
                    <TableContainer>
                        {error && <p>{error}</p>}
                        <AnimatePresence mode="wait">
                            {isLoading ? (
                                <motion.div
                                    key="loading"
                                    initial={{ opacity: 0 }}
                                    animate={{ opacity: 1 }}
                                    exit={{ opacity: 0 }}
                                    transition={{ duration: 0.3 }}
                                >
                                    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100px' }}>
                                        <LoadingAnimation />
                                    </Box>
                                </motion.div>
                            ) : (
                                <motion.div
                                    key="table"
                                    initial={{ opacity: 0 }}
                                    animate={{ opacity: 1 }}
                                    exit={{ opacity: 0 }}
                                    transition={{ duration: 0.5 }}
                                >
                                    <Table className="table-auto w-full" aria-label="Impact Metrics Table">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell component="th" style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', ...responsiveStyle, ...responsiveCellStyle }}>
                                                    <TableSortLabel
                                                        active={false}
                                                        direction={'asc'}
                                                    >
                                                        Rank
                                                    </TableSortLabel>
                                                </TableCell>
                                                <TableCell
                                                    component="th"
                                                    className="sticky left-0 z-10 bg-white responsive-table-header"
                                                >
                                                    <Typography
                                                        sx={{
                                                            whiteSpace: 'nowrap',
                                                            overflow: 'hidden',
                                                            textOverflow: 'ellipsis',
                                                            minWidth: '120px', // Ensure you use string with px for inline styles in MUI sx prop
                                                            position: 'sticky',
                                                            left: 0,
                                                            zIndex: 10, // Ensure zIndex is high enough to be on top of other elements
                                                        }}
                                                        style={{ ...responsiveStyle, ...responsiveCellStyle }}
                                                    >
                                                        Player
                                                    </Typography>
                                                </TableCell>
                                                <TableCell
                                                    component="th"
                                                    style={{
                                                        whiteSpace: 'nowrap',
                                                        overflow: 'hidden',
                                                        textOverflow: 'ellipsis',
                                                        textAlign: 'center',
                                                        ...responsiveStyle,
                                                        ...responsiveCellStyle
                                                    }}
                                                >
                                                    <TableSortLabel
                                                        active={orderBy === 'age'}
                                                        direction={orderBy === 'age' ? orderDirection : 'desc'}
                                                        onClick={() => handleSortRequest('age')}
                                                    >
                                                        Age
                                                    </TableSortLabel>
                                                </TableCell>
                                                <TableCell
                                                    component="th"
                                                    style={{
                                                        whiteSpace: 'nowrap',
                                                        overflow: 'hidden',
                                                        textOverflow: 'ellipsis',
                                                        textAlign: 'center',
                                                        ...responsiveStyle,
                                                        ...responsiveCellStyle
                                                    }}
                                                >
                                                    <TableSortLabel
                                                        active={orderBy === 'games_played'}
                                                        direction={orderBy === 'games_played' ? orderDirection : 'desc'}
                                                        onClick={() => handleSortRequest('games_played')}
                                                    >
                                                        GP
                                                    </TableSortLabel>
                                                </TableCell>
                                                <TableCell
                                                    component="th"
                                                    style={{
                                                        whiteSpace: 'nowrap',
                                                        overflow: 'hidden',
                                                        textOverflow: 'ellipsis',
                                                        textAlign: 'center',
                                                        ...responsiveStyle,
                                                        ...responsiveCellStyle
                                                    }}
                                                >
                                                    <TableSortLabel
                                                        active={orderBy === 'min_played'}
                                                        direction={orderBy === 'min_played' ? orderDirection : 'desc'}
                                                        onClick={() => handleSortRequest('min_played')}
                                                    >
                                                        MIN
                                                    </TableSortLabel>
                                                </TableCell>
                                                <TableCell
                                                    component="th"
                                                    style={{
                                                        whiteSpace: 'nowrap',
                                                        overflow: 'hidden',
                                                        textOverflow: 'ellipsis',
                                                        textAlign: 'center',
                                                        ...responsiveStyle,
                                                        ...responsiveCellStyle
                                                    }}
                                                >
                                                    <TableSortLabel
                                                        active={orderBy === 'o_pipm'}
                                                        direction={orderBy === 'o_pipm' ? orderDirection : 'desc'}
                                                        onClick={() => handleSortRequest('o_pipm')}
                                                    >
                                                        O-PIPM
                                                    </TableSortLabel>
                                                </TableCell>
                                                <TableCell
                                                    component="th"
                                                    style={{
                                                        whiteSpace: 'nowrap',
                                                        overflow: 'hidden',
                                                        textOverflow: 'ellipsis',
                                                        textAlign: 'center',
                                                        ...responsiveStyle,
                                                        ...responsiveCellStyle
                                                    }}
                                                >
                                                    <TableSortLabel
                                                        active={orderBy === 'd_pipm'}
                                                        direction={orderBy === 'd_pipm' ? orderDirection : 'desc'}
                                                        onClick={() => handleSortRequest('d_pipm')}
                                                    >
                                                        D-PIPM
                                                    </TableSortLabel>
                                                </TableCell>
                                                <TableCell
                                                    component="th"
                                                    style={{
                                                        whiteSpace: 'nowrap',
                                                        overflow: 'hidden',
                                                        textOverflow: 'ellipsis',
                                                        textAlign: 'center',
                                                        ...responsiveStyle,
                                                        ...responsiveCellStyle
                                                    }}
                                                >
                                                    <TableSortLabel
                                                        active={orderBy === 'pipm'}
                                                        direction={orderBy === 'pipm' ? orderDirection : 'desc'}
                                                        onClick={() => handleSortRequest('pipm')}
                                                    >
                                                        PIPM
                                                    </TableSortLabel>
                                                </TableCell>
                                                <TableCell
                                                    component="th"
                                                    style={{
                                                        whiteSpace: 'nowrap',
                                                        overflow: 'hidden',
                                                        textOverflow: 'ellipsis',
                                                        textAlign: 'center',
                                                        ...responsiveStyle,
                                                        ...responsiveCellStyle
                                                    }}
                                                >
                                                    <TableSortLabel
                                                        active={orderBy === 'o_bpm'}
                                                        direction={orderBy === 'o_bpm' ? orderDirection : 'desc'}
                                                        onClick={() => handleSortRequest('o_bpm')}
                                                    >
                                                        O-BPM
                                                    </TableSortLabel>
                                                </TableCell>
                                                <TableCell
                                                    component="th"
                                                    style={{
                                                        whiteSpace: 'nowrap',
                                                        overflow: 'hidden',
                                                        textOverflow: 'ellipsis',
                                                        textAlign: 'center',
                                                        ...responsiveStyle,
                                                        ...responsiveCellStyle
                                                    }}
                                                >
                                                    <TableSortLabel
                                                        active={orderBy === 'd_bpm'}
                                                        direction={orderBy === 'd_bpm' ? orderDirection : 'desc'}
                                                        onClick={() => handleSortRequest('d_bpm')}
                                                    >
                                                        D-BPM
                                                    </TableSortLabel>
                                                </TableCell>
                                                <TableCell
                                                    component="th"
                                                    style={{
                                                        whiteSpace: 'nowrap',
                                                        overflow: 'hidden',
                                                        textOverflow: 'ellipsis',
                                                        textAlign: 'center',
                                                        ...responsiveStyle,
                                                        ...responsiveCellStyle
                                                    }}
                                                >
                                                    <TableSortLabel
                                                        active={orderBy === 'bpm'}
                                                        direction={orderBy === 'bpm' ? orderDirection : 'desc'}
                                                        onClick={() => handleSortRequest('bpm')}
                                                    >
                                                        BPM
                                                    </TableSortLabel>
                                                </TableCell>
                                                <TableCell
                                                    component="th"
                                                    style={{
                                                        whiteSpace: 'nowrap',
                                                        overflow: 'hidden',
                                                        textOverflow: 'ellipsis',
                                                        textAlign: 'center', // Add this line
                                                        ...responsiveStyle,
                                                        ...responsiveCellStyle
                                                    }}
                                                >
                                                    <TableSortLabel
                                                        active={orderBy === 'vorp'}
                                                        direction={orderBy === 'vorp' ? orderDirection : 'desc'}
                                                        onClick={() => handleSortRequest('vorp')}
                                                    >
                                                        VORP
                                                    </TableSortLabel>
                                                </TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {sortedAndFilteredData.map((player, index) => (
                                                <TableRow
                                                    key={`${player.player_id}_${player.team_id}`}
                                                    sx={{
                                                        '& > *': { py: '2px' },
                                                        '&:hover': {
                                                            '& .MuiTableCell-root:not(.player-name-cell)': {
                                                                filter: 'brightness(90%)',
                                                            },
                                                            '& .player-name-cell': {
                                                                backgroundColor: 'rgb(240, 238, 245)',
                                                            },
                                                        },
                                                    }}
                                                >
                                                    <TableCell className="left-column" sx={commonCellStyle} style={responsiveStyle}>{index + 1}</TableCell>
                                                    <TableCell
                                                        component="th"
                                                        scope="row"
                                                        className="sticky left-0 z-10 player-name-cell"
                                                        sx={{
                                                            ...commonCellStyle,
                                                            backgroundColor: 'rgb(250, 248, 255)',
                                                            position: 'sticky',
                                                            left: 0,
                                                            zIndex: 10,
                                                        }}
                                                    >
                                                        <Link
                                                            to={`/player-profile/${player.player_id}`}
                                                            style={{ textDecoration: 'none', color: 'inherit' }}
                                                            className="player-link"
                                                        >
                                                            <Typography
                                                                variant="body1"
                                                                sx={{
                                                                    color: 'black',
                                                                    fontSize: '0.875rem',
                                                                    whiteSpace: 'nowrap',
                                                                    overflow: 'hidden',
                                                                    textOverflow: 'ellipsis',
                                                                    maxWidth: '100%',
                                                                    textDecoration: 'underline',
                                                                    '&:hover': {
                                                                        color: '#8685EF'
                                                                    }
                                                                }}
                                                                style={{ ...responsiveStyle, ...responsiveRowStyle }}
                                                            >
                                                                {showJapanese ? player.player_name : player.player_name_eng}
                                                            </Typography>
                                                        </Link>
                                                        <Typography
                                                            variant="body2"
                                                            sx={{
                                                                color: 'grey',
                                                                fontSize: '0.75rem',
                                                                whiteSpace: 'nowrap',
                                                                overflow: 'hidden',
                                                                textOverflow: 'ellipsis',
                                                                maxWidth: '100%'
                                                            }}
                                                            style={{ ...responsiveStyle, ...responsiveRowStyle }}
                                                        >
                                                            {showJapanese ? player.team_name : player.team_name_eng} - {player.position}
                                                        </Typography>
                                                    </TableCell>
                                                    <TableCell
                                                        className="left-column"
                                                        sx={{
                                                            ...commonCellStyle,
                                                            textAlign: 'center'
                                                        }}
                                                        style={responsiveStyle}
                                                    >
                                                        {player.age}
                                                    </TableCell>
                                                    <TableCell
                                                        className="left-column"
                                                        sx={{
                                                            ...commonCellStyle,
                                                            textAlign: 'center'
                                                        }}
                                                        style={responsiveStyle}
                                                    >
                                                        {player.games_played}
                                                    </TableCell>
                                                    <TableCell
                                                        className="left-column"
                                                        sx={{
                                                            ...commonCellStyle,
                                                            textAlign: 'center'
                                                        }}
                                                        style={responsiveStyle}
                                                    >
                                                        {player.min_played.toFixed(1)}
                                                    </TableCell>
                                                    <ColoredCell
                                                        value={player.o_pipm}
                                                        percentile={percentiles['o_pipm']?.[player.o_pipm]}
                                                        format={value => value > 0 ? `+${value.toFixed(2)}` : value.toFixed(2)}
                                                        sx={commonCellStyle}
                                                    />
                                                    <ColoredCell
                                                        value={player.d_pipm}
                                                        percentile={percentiles['d_pipm']?.[player.d_pipm]}
                                                        format={value => value > 0 ? `+${value.toFixed(2)}` : value.toFixed(2)}
                                                        sx={commonCellStyle}
                                                    />
                                                    <ColoredCell
                                                        value={player.pipm}
                                                        percentile={percentiles['pipm']?.[player.pipm]}
                                                        format={value => value > 0 ? `+${value.toFixed(2)}` : value.toFixed(2)}
                                                        sx={commonCellStyle}
                                                    />
                                                    <ColoredCell
                                                        value={player.o_bpm}
                                                        percentile={percentiles['o_bpm']?.[player.o_bpm]}
                                                        format={value => value > 0 ? `+${value.toFixed(2)}` : value.toFixed(2)}
                                                        sx={commonCellStyle}
                                                    />
                                                    <ColoredCell
                                                        value={player.d_bpm}
                                                        percentile={percentiles['d_bpm']?.[player.d_bpm]}
                                                        format={value => value > 0 ? `+${value.toFixed(2)}` : value.toFixed(2)}
                                                        sx={commonCellStyle}
                                                    />
                                                    <ColoredCell
                                                        value={player.bpm}
                                                        percentile={percentiles['bpm']?.[player.bpm]}
                                                        format={value => value > 0 ? `+${value.toFixed(2)}` : value.toFixed(2)}
                                                        sx={commonCellStyle}
                                                    />
                                                    <ColoredCell
                                                        value={player.vorp}
                                                        percentile={percentiles['vorp']?.[player.vorp]}
                                                        format={value => value > 0 ? `+${value.toFixed(2)}` : value.toFixed(2)}
                                                        sx={commonCellStyle}
                                                    />
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </motion.div>
                            )}
                        </AnimatePresence>
                    </TableContainer>
                </div>
            </Container>
        </PageTransition>
    );
};

export default ImpactMetricsTable;