import React, { useState, useEffect} from 'react';
import axios from 'axios';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Switch } from '@mui/material';
import { TableSortLabel } from '@mui/material';
import { Box } from '@mui/material';
import { Select, MenuItem, FormControl, InputLabel } from '@mui/material';
import { motion, AnimatePresence } from 'framer-motion';
import LoadingAnimation from './LoadingAnimation';
import './ImpactMetricsTable.css';
import PageTransition from './PageTransition';
import { SeasonDropdown, LeagueDropdown } from './Dropdowns';
import { seasons } from './constants';
import ColoredCell, { calculatePercentiles } from './ColoringLogic';
import { getComparator } from './SortingLogic';

const baseUrl = process.env.REACT_APP_API_URL;

const TeamStatsTable = () => {
    const [seasonId, setSeasonId] = useState('202324');
    const [leagueId, setLeagueId] = useState('1');
    const [data, setData] = useState([]);
    const [error, setError] = useState(null);
    const [divisionFilter, setDivisionFilter] = useState({ japanese: '', english: '' });
    const [percentiles, setPercentiles] = useState({});
    const [orderDirection, setOrderDirection] = useState('desc'); // 'asc' or 'desc'
    const [orderBy, setOrderBy] = useState('net_rating'); // default column to sort by
    const [divisionOptions, setDivisionOptions] = useState([]);
    const responsiveStyle = window.innerWidth <= 600 ? { fontSize: '11px' } : {};
    const responsiveCellStyle = window.innerWidth <= 600 ? { padding: '2px', minWidth: '30px' } : { padding: '2px', minWidth: '80px' };
    const responsiveRowStyle = window.innerWidth <= 600 ? { padding: '0px', minHeight: '10px' } : { padding: '0px', minHeight: '10px' };
    const [showJapanese, setShowJapanese] = useState(true);
    const [isLoading, setIsLoading] = useState(false);


    useEffect(() => {
        setIsLoading(true);
        axios.get(`${baseUrl}/api/team-stats/?season_id=${seasonId}&league_id=${leagueId}`)
            .then(response => {
                setData(response.data);
                setPercentiles(calculatePercentiles(response.data, ['offensive_rating', 'defensive_rating', 'net_rating', 'pace', 'efg_pct', 'tov_pct', 'orb_pct', 'ft_rate', 'opp_efg_pct', 'opp_tov_pct', 'opp_orb_pct', 'opp_ft_rate']));
                updateDivisionOptions(response.data);
                setIsLoading(false);
            })
            .catch(error => {
                console.error("Failed to fetch shot quality data", error);
                setError("Failed to fetch data");
                setIsLoading(false);
            });
    }, [seasonId, leagueId]);

    const updateDivisionOptions = (data) => {
        const divisions = data.map(team => ({
            japanese: team.division,
            english: team.division_eng
        }));
        const uniqueDivisions = Array.from(new Set(divisions.map(JSON.stringify)), JSON.parse);
        setDivisionOptions(uniqueDivisions);
    };

    const handleSortRequest = (columnId) => {
        const isAsc = orderBy === columnId && orderDirection === 'desc';
        setOrderDirection(isAsc ? 'asc' : 'desc');
        setOrderBy(columnId);
    };

    const commonCellStyle = {
        transition: 'all 0.2s ease',
    };

    // Updated handleDivisionFilterChange function
    const handleDivisionFilterChange = (event) => {
        const selectedValue = event.target.value;
        // Directly set the divisionFilter state
        const selectedDivision = divisionOptions.find(division => 
            division.japanese === selectedValue || division.english === selectedValue);

        if (selectedDivision) {
            setDivisionFilter(selectedDivision);
        } else {
            setDivisionFilter({ japanese: '', english: '' });
        }
    };

    const sortedAndFilteredData = data
        .filter(team => {
            return showJapanese
                ? (!divisionFilter.japanese || team.division === divisionFilter.japanese)
                : (!divisionFilter.english || team.division_eng === divisionFilter.english);
        })
        .sort(getComparator(orderDirection, orderBy));


    return (
        <PageTransition>
            <Container maxWidth="lg" sx={{ my: 10 }}>
                <Typography variant="h4" gutterBottom className="impact-metrics-title">
                    B.LEAGUE <span className="impact-metrics-break">Team Ratings / Four Factors</span>
                </Typography>
                <Box
                    sx={{
                        display: "flex",
                        flexWrap: { xs: 'wrap', sm: 'nowrap' }, // Wrap on extra-small screens, no wrap on small screens and up
                        alignItems: "center",
                        overflowX: 'auto' // Horizontal scroll on overflow
                    }}
                >
                    <SeasonDropdown
                        currentSeasonId={seasonId}
                        setSeasonId={setSeasonId}
                        seasons={seasons}
                        className="impact-metrics-formControl"
                    />
                    <LeagueDropdown
                        selectedLeague={leagueId}
                        setLeagueId={setLeagueId}
                        className="impact-metrics-formControl"
                    />
                    <FormControl className="team-stats-formControl" variant="outlined" sx={{ m: 1, minWidth: 150 }}>
                        <InputLabel>Division</InputLabel>
                        <Select
                            value={showJapanese ? divisionFilter.japanese : divisionFilter.english}
                            onChange={handleDivisionFilterChange}
                            label="Division"
                        >
                            <MenuItem value="">
                                <em>All</em>
                            </MenuItem>
                            {divisionOptions.map((division, index) => (
                                <MenuItem key={index} value={showJapanese ? division.japanese : division.english}>
                                    {showJapanese ? division.japanese : division.english}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Box>
                <Box
                    sx={{
                        display: "flex",
                        flexWrap: { xs: 'wrap', sm: 'nowrap' },
                        alignItems: "center",
                        overflowX: 'auto'
                    }}
                >
                    <Typography variant="body2" sx={{ mr: 1 }}>
                        English
                    </Typography>
                    <Switch
                        checked={!showJapanese} // Flipping the value
                        onChange={() => setShowJapanese(!showJapanese)} // Same handler to toggle language
                        name="languageToggle"
                        inputProps={{ 'aria-label': 'language toggle' }}
                    />
                </Box>
                <div style={{ overflowX: 'auto' }}>
                    <TableContainer>
                        {error && <p>{error}</p>}
                        <AnimatePresence mode="wait">
                            {isLoading ? (
                                <motion.div
                                    key="loading"
                                    initial={{ opacity: 0 }}
                                    animate={{ opacity: 1 }}
                                    exit={{ opacity: 0 }}
                                    transition={{ duration: 0.3 }}
                                >
                                    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100px' }}>
                                        <LoadingAnimation />
                                    </Box>
                                </motion.div>
                            ) : (
                                <motion.div
                                    key="table"
                                    initial={{ opacity: 0 }}
                                    animate={{ opacity: 1 }}
                                    exit={{ opacity: 0 }}
                                    transition={{ duration: 0.5 }}
                                >
                                <Table className="table-auto w-full" aria-label="Team Stats Table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell component="th" style={{ ...responsiveStyle, ...responsiveCellStyle, borderBottom: 'none' }} colSpan={7}></TableCell>
                                            <TableCell component="th" style={{ ...responsiveStyle, ...responsiveCellStyle, textAlign: 'center', borderBottom: '2px solid black' }} colSpan={4}>
                                                OWN TEAM
                                            </TableCell>
                                            <TableCell style={{ borderBottom: 'none' }}></TableCell>
                                            <TableCell component="th" style={{ ...responsiveStyle, ...responsiveCellStyle, textAlign: 'center', borderBottom: '2px solid black' }} colSpan={4}>
                                                OPPONENT
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell component="th" style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', textAlign: 'center', ...responsiveStyle, ...responsiveCellStyle }}>
                                                <TableSortLabel
                                                    active={false}
                                                    direction={'asc'}
                                                >
                                                    Rank
                                                </TableSortLabel>
                                            </TableCell>
                                            <TableCell
                                                component="th"
                                                className="sticky left-0 z-10 bg-white responsive-table-header"
                                            >
                                                <Typography
                                                    variant="body1"
                                                    sx={{
                                                        whiteSpace: 'nowrap',
                                                        overflow: 'hidden',
                                                        textOverflow: 'ellipsis',
                                                        minWidth: '120px', // Ensure you use string with px for inline styles in MUI sx prop
                                                        position: 'sticky',
                                                        left: 0,
                                                        zIndex: 10, // Ensure zIndex is high enough to be on top of other elements
                                                    }}
                                                    style={{ ...responsiveStyle, ...responsiveCellStyle }}
                                                >
                                                    TEAM
                                                </Typography>
                                            </TableCell>
                                            <TableCell component="th" style={{ textAlign: 'center', ...responsiveStyle, ...responsiveCellStyle }}>
                                                <TableSortLabel
                                                    active={orderBy === 'offensive_rating'}
                                                    direction={orderBy === 'offensive_rating' ? orderDirection : 'desc'}
                                                    onClick={() => handleSortRequest('offensive_rating')}
                                                >
                                                    ORTG
                                                </TableSortLabel>
                                            </TableCell>
                                            <TableCell component="th" style={{ textAlign: 'center', ...responsiveStyle, ...responsiveCellStyle }}>
                                                <TableSortLabel
                                                    active={orderBy === 'defensive_rating'}
                                                    direction={orderBy === 'defensive_rating' ? orderDirection : 'desc'}
                                                    onClick={() => handleSortRequest('defensive_rating')}
                                                >
                                                    DRTG
                                                </TableSortLabel>
                                            </TableCell>
                                            <TableCell component="th" style={{ textAlign: 'center', ...responsiveStyle, ...responsiveCellStyle }}>
                                                <TableSortLabel
                                                    active={orderBy === 'net_rating'}
                                                    direction={orderBy === 'net_rating' ? orderDirection : 'desc'}
                                                    onClick={() => handleSortRequest('net_rating')}
                                                >
                                                    Net RTG
                                                </TableSortLabel>
                                            </TableCell>
                                            <TableCell component="th" style={{ textAlign: 'center', ...responsiveStyle, ...responsiveCellStyle }}>
                                                <TableSortLabel
                                                    active={orderBy === 'pace'}
                                                    direction={orderBy === 'pace' ? orderDirection : 'desc'}
                                                    onClick={() => handleSortRequest('pace')}
                                                >
                                                    Pace
                                                </TableSortLabel>
                                            </TableCell>
                                            <TableCell></TableCell>
                                            <TableCell component="th" style={{ textAlign: 'center', ...responsiveStyle, ...responsiveCellStyle }}>
                                                <TableSortLabel
                                                    active={orderBy === 'efg_pct'}
                                                    direction={orderBy === 'efg_pct' ? orderDirection : 'desc'}
                                                    onClick={() => handleSortRequest('efg_pct')}
                                                >
                                                    eFG%
                                                </TableSortLabel>
                                            </TableCell>
                                            <TableCell component="th" style={{ textAlign: 'center', ...responsiveStyle, ...responsiveCellStyle }}>
                                                <TableSortLabel
                                                    active={orderBy === 'tov_pct'}
                                                    direction={orderBy === 'tov_pct' ? orderDirection : 'desc'}
                                                    onClick={() => handleSortRequest('tov_pct')}
                                                >
                                                    TOV%
                                                </TableSortLabel>
                                            </TableCell>
                                            <TableCell component="th" style={{ textAlign: 'center', ...responsiveStyle, ...responsiveCellStyle }}>
                                                <TableSortLabel
                                                    active={orderBy === 'orb_pct'}
                                                    direction={orderBy === 'orb_pct' ? orderDirection : 'desc'}
                                                    onClick={() => handleSortRequest('orb_pct')}
                                                >
                                                    OREB%
                                                </TableSortLabel>
                                            </TableCell>
                                            <TableCell component="th" style={{ textAlign: 'center', ...responsiveStyle, ...responsiveCellStyle }}>
                                                <TableSortLabel
                                                    active={orderBy === 'ft_rate'}
                                                    direction={orderBy === 'ft_rate' ? orderDirection : 'desc'}
                                                    onClick={() => handleSortRequest('ft_rate')}
                                                >
                                                    FTr
                                                </TableSortLabel>
                                            </TableCell>
                                            <TableCell></TableCell>
                                            <TableCell component="th" style={{ textAlign: 'center', ...responsiveStyle, ...responsiveCellStyle }}>
                                                <TableSortLabel
                                                    active={orderBy === 'opp_efg_pct'}
                                                    direction={orderBy === 'opp_efg_pct' ? orderDirection : 'desc'}
                                                    onClick={() => handleSortRequest('opp_efg_pct')}
                                                >
                                                    eFG%
                                                </TableSortLabel>
                                            </TableCell>
                                            <TableCell component="th" style={{ textAlign: 'center', ...responsiveStyle, ...responsiveCellStyle }}>
                                                <TableSortLabel
                                                    active={orderBy === 'opp_tov_pct'}
                                                    direction={orderBy === 'opp_tov_pct' ? orderDirection : 'desc'}
                                                    onClick={() => handleSortRequest('opp_tov_pct')}
                                                >
                                                    TOV%
                                                </TableSortLabel>
                                            </TableCell>
                                            <TableCell component="th" style={{ textAlign: 'center', ...responsiveStyle, ...responsiveCellStyle }}>
                                                <TableSortLabel
                                                    active={orderBy === 'opp_orb_pct'}
                                                    direction={orderBy === 'opp_orb_pct' ? orderDirection : 'desc'}
                                                    onClick={() => handleSortRequest('opp_orb_pct')}
                                                >
                                                    OREB%
                                                </TableSortLabel>
                                            </TableCell>
                                            <TableCell component="th" style={{ textAlign: 'center', ...responsiveStyle, ...responsiveCellStyle }}>
                                                <TableSortLabel
                                                    active={orderBy === 'opp_ft_rate'}
                                                    direction={orderBy === 'opp_ft_rate' ? orderDirection : 'desc'}
                                                    onClick={() => handleSortRequest('opp_ft_rate')}
                                                >
                                                    FTr
                                                </TableSortLabel>
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                            {sortedAndFilteredData.map((team, index) => (
                                                <TableRow
                                                    key={`${team.team_id}`}
                                                    sx={{
                                                        '& > *': { py: '2px' },
                                                        '&:hover': {
                                                            '& .MuiTableCell-root:not(.player-name-cell)': {
                                                                filter: 'brightness(90%)',
                                                            },
                                                            '& .player-name-cell': {
                                                                backgroundColor: 'rgb(240, 238, 245)',
                                                            },
                                                        },
                                                    }}
                                                >
                                                <TableCell className="left-column" sx={commonCellStyle} style={responsiveStyle}>{index + 1}</TableCell>
                                                <TableCell
                                                        component="th"
                                                        scope="row"
                                                        className="sticky left-0 z-10 player-name-cell"
                                                        sx={{
                                                            ...commonCellStyle,
                                                            backgroundColor: 'rgb(250, 248, 255)',
                                                            position: 'sticky',
                                                            left: 0,
                                                            zIndex: 10,
                                                        }}
                                                    >
                                                        <Typography
                                                            variant="body1"
                                                            sx={{
                                                                color: 'black',
                                                                fontSize: '0.875rem',
                                                                whiteSpace: 'nowrap',
                                                                overflow: 'hidden',
                                                                textOverflow: 'ellipsis',
                                                                maxWidth: '100%'
                                                            }}
                                                            style={{ ...responsiveStyle, ...responsiveRowStyle }}
                                                        >
                                                            {showJapanese ? team.name_short : team.team_name_eng}
                                                        </Typography>
                                                        <Typography
                                                            variant="body2"
                                                            sx={{
                                                                color: 'grey',
                                                                fontSize: '0.75rem',
                                                                whiteSpace: 'nowrap',
                                                                overflow: 'hidden',
                                                                textOverflow: 'ellipsis',
                                                                maxWidth: '100%'
                                                            }}
                                                            style={{ ...responsiveStyle, ...responsiveRowStyle }}
                                                        >
                                                            {showJapanese ? team.division : team.division_eng}
                                                        </Typography>
                                                    </TableCell>
                                                    <ColoredCell
                                                        value={team.offensive_rating}
                                                        percentile={percentiles['offensive_rating']?.[team.offensive_rating]}
                                                        format={value => `${value.toFixed(2)}`}
                                                        sx={commonCellStyle}
                                                    />
                                                    <ColoredCell
                                                        value={team.defensive_rating}
                                                        percentile={percentiles['defensive_rating']?.[team.defensive_rating]}
                                                        format={value => `${value.toFixed(2)}`}
                                                        sx={commonCellStyle}
                                                        reverse={true}
                                                    />
                                                    <ColoredCell
                                                        value={team.net_rating}
                                                        percentile={percentiles['net_rating']?.[team.net_rating]}
                                                        format={value => value > 0 ? `+${value.toFixed(2)}` : value.toFixed(2)}
                                                        sx={commonCellStyle}
                                                    />
                                                    <ColoredCell
                                                        value={team.pace}
                                                        percentile={percentiles['pace']?.[team.pace]}
                                                        format={value => `${value.toFixed(2)}`}
                                                        sx={commonCellStyle}
                                                    />
                                                    <TableCell className="left-column"></TableCell>
                                                    <ColoredCell
                                                        value={team.efg_pct}
                                                        percentile={percentiles['efg_pct']?.[team.efg_pct]}
                                                        format={(value) => `${(value * 100).toFixed(2)}%`}
                                                        sx={commonCellStyle}
                                                    />
                                                    <ColoredCell
                                                        value={team.tov_pct}
                                                        percentile={percentiles['tov_pct']?.[team.tov_pct]}
                                                        format={(value) => `${(value * 100).toFixed(2)}%`}
                                                        sx={commonCellStyle}
                                                        reverse={true}
                                                    />
                                                    <ColoredCell
                                                        value={team.orb_pct}
                                                        percentile={percentiles['orb_pct']?.[team.orb_pct]}
                                                        format={(value) => `${(value * 100).toFixed(2)}%`}
                                                        sx={commonCellStyle}
                                                    />
                                                    <ColoredCell
                                                        value={team.ft_rate}
                                                        percentile={percentiles['ft_rate']?.[team.ft_rate]}
                                                        format={(value) => `${(value * 100).toFixed(2)}%`}
                                                        sx={commonCellStyle}
                                                    />
                                                    <TableCell className="left-column"></TableCell>
                                                    <ColoredCell
                                                        value={team.opp_efg_pct}
                                                        percentile={percentiles['opp_efg_pct']?.[team.opp_efg_pct]}
                                                        format={(value) => `${(value * 100).toFixed(2)}%`}
                                                        sx={commonCellStyle}
                                                        reverse={true}
                                                    />
                                                    <ColoredCell
                                                        value={team.opp_tov_pct}
                                                        percentile={percentiles['opp_tov_pct']?.[team.opp_tov_pct]}
                                                        format={(value) => `${(value * 100).toFixed(2)}%`}
                                                        sx={commonCellStyle}
                                                    />
                                                    <ColoredCell
                                                        value={team.opp_orb_pct}
                                                        percentile={percentiles['opp_orb_pct']?.[team.opp_orb_pct]}
                                                        format={(value) => `${(value * 100).toFixed(2)}%`}
                                                        sx={commonCellStyle}
                                                        reverse={true}
                                                    />
                                                    <ColoredCell
                                                        value={team.opp_ft_rate}
                                                        percentile={percentiles['opp_ft_rate']?.[team.opp_ft_rate]}
                                                        format={(value) => `${(value * 100).toFixed(2)}%`}
                                                        sx={commonCellStyle}
                                                        reverse={true}
                                                    />
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </motion.div>
                            )}
                        </AnimatePresence>
                    </TableContainer>
                </div>
            </Container>
        </PageTransition>
    );
};

export default TeamStatsTable;