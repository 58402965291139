import React from 'react';

const LoadingAnimation = () => {
  const bars = 12;
  const color = '#ABABAB';

  return (
    <div className="loading-animation" style={{ width: '40px', height: '40px', position: 'relative' }}>
      {[...Array(bars)].map((_, i) => (
        <div
          key={i}
          style={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            width: '25%',
            height: '8%',
            backgroundColor: color,
            transform: `rotate(${i * (360 / bars)}deg) translate(120%, 0)`,
            animation: `fade 1s linear ${i * (1 / bars)}s infinite`,
            borderRadius: '5px',
          }}
        />
      ))}
      <style jsx>{`
        @keyframes fade {
          0%, 100% { opacity: 0.1; }
          50% { opacity: 1; }
        }
      `}</style>
    </div>
  );
};

export default LoadingAnimation;