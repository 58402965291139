export const seasons = ['202324', '202223', '202122', '202021', '201920', '201819', '201718'];

export const seasonsShotData = ['202324', '202223'];

export const rosterTypeMapping = {
    1: { jp: '日本人', en: 'Japanese' },
    2: { jp: '外国籍', en: 'Import' },
    3: { jp: '帰化', en: 'Naturalized' },
    4: { jp: 'アジア', en: 'Asian Quota' }
};

export const positionOrder = ['PG', 'PG/SG', 'SG', 'SG/SF', 'SF', 'SF/PF', 'PF', 'C/PF', 'C'];

export const metricsGPM = ['o_gpm', 'd_gpm', 'gpm', 'usage', 'true_shooting'];

export const impactMetrics = ['o_pipm', 'd_pipm', 'pipm', 'o_bpm', 'd_bpm', 'bpm', 'vorp'];

export const advancedMetrics = ['true_shooting', 'effective_fg', 'three_point_attempt_rate', 'free_throw_attempt_rate', 'usage', 'ast_to_ratio', 'ast_rate', 'la_ortg', 'la_drtg', 'la_netrtg'];

export const onOffMetrics = ['own_efg_onoff', 'opp_efg_onoff', 'own_tovr_onoff', 'opp_tovr_onoff', 'own_orebr_onoff', 'opp_orebr_onoff', 'own_ftrate_onoff', 'opp_ftrate_onoff', 'o_onoff', 'adjusted_d_onoff', 'net_onoff'];

export const playerShotMetrics = ['rim_accuracy', 'rim_frequency', 'short_midrange_accuracy', 'short_midrange_frequency', 'long_midrange_accuracy', 'long_midrange_frequency', 'above_break_three_accuracy', 'above_break_three_frequency', 'corner_three_accuracy', 'corner_three_frequency'];


