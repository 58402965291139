// WOWYTable.js
import React, { useState } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TableSortLabel } from '@mui/material';
import PageTransition from './PageTransition';
import './ImpactMetricsTable.css';

const WOWYTable = ({ data, showJapanese }) => {
    const [orderDirection, setOrderDirection] = useState('desc'); // 'asc' or 'desc'
    const [orderBy, setOrderBy] = useState('possessions'); // default column to sort by
    const responsiveStyle = {
        ...(window.innerWidth <= 600 ? { fontSize: '11px' } : {}),
        whiteSpace: 'nowrap', // Prevents text from wrapping
        overflow: 'hidden', // Keeps the content from spilling out
        textOverflow: 'ellipsis', // Adds an ellipsis if the content is too long
        textAlign: 'center'
    };
    const responsiveCellStyle = window.innerWidth <= 600 ? { padding: '2px', minWidth: '30px' } : { padding: '2px', minWidth: '80px' };

    const handleSortRequest = (columnId) => {
        const isAsc = orderBy === columnId && orderDirection === 'desc';
        setOrderDirection(isAsc ? 'asc' : 'desc');
        setOrderBy(columnId);
    };

    const getComparator = (order, orderBy) => {
        return order === 'desc'
            ? (a, b) => descendingComparator(a, b, orderBy)
            : (a, b) => -descendingComparator(a, b, orderBy);
    };

    const sortData = (data, comparator) => {
        const stabilizedThis = data.map((el, index) => [el, index]);
        stabilizedThis.sort((a, b) => {
            const order = comparator(a[0], b[0]);
            if (order !== 0) return order;
            return a[1] - b[1];
        });
        return stabilizedThis.map((el) => el[0]);
    };

    const descendingComparator = (a, b, orderBy) => {
        if (b[orderBy] < a[orderBy]) {
            return -1;
        }
        if (b[orderBy] > a[orderBy]) {
            return 1;
        }
        return 0;
    };

    const sortedData = sortData(data, getComparator(orderDirection, orderBy));

    const commonCellStyle = {
        transition: 'all 0.2s ease',
    };

    return (
        <PageTransition>
            <div style={{ overflowX: 'auto' }}>
                <TableContainer>
                    <Table aria-label="WOWY Combinations Table">
                        <TableHead>
                            <TableRow>
                                <TableCell component="th" style={{ ...responsiveStyle, ...responsiveCellStyle, borderBottom: 'none' }} colSpan={6}></TableCell>
                                <TableCell component="th" style={{ ...responsiveStyle, ...responsiveCellStyle, textAlign: 'center', borderBottom: '2px solid black' }} colSpan={2}>
                                    OWN TEAM
                                </TableCell>
                                <TableCell style={{ borderBottom: 'none' }}></TableCell>
                                <TableCell component="th" style={{ ...responsiveStyle, ...responsiveCellStyle, textAlign: 'center', borderBottom: '2px solid black' }} colSpan={2}>
                                    OPPONENT
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell component="th" style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', textAlign: 'center', ...responsiveStyle, ...responsiveCellStyle }}>Players On</TableCell>
                                <TableCell component="th" style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', textAlign: 'center', ...responsiveStyle, ...responsiveCellStyle }}>Players Off</TableCell>
                                <TableCell component="th" style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', textAlign: 'center', ...responsiveStyle, ...responsiveCellStyle }}>
                                    <TableSortLabel
                                        active={orderBy === 'possessions'}
                                        direction={orderBy === 'possessions' ? orderDirection : 'possessions'}
                                        onClick={() => handleSortRequest('possessions')}
                                    >
                                        Poss.
                                    </TableSortLabel>
                                </TableCell>
                                <TableCell component="th" style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', textAlign: 'center', ...responsiveStyle, ...responsiveCellStyle }}>
                                    <TableSortLabel
                                        active={orderBy === 'ORTG'}
                                        direction={orderBy === 'ORTG' ? orderDirection : 'desc'}
                                        onClick={() => handleSortRequest('ORTG')}
                                    >
                                        ORTG
                                    </TableSortLabel>
                                </TableCell>
                                <TableCell component="th" style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', textAlign: 'center', ...responsiveStyle, ...responsiveCellStyle }}>
                                    <TableSortLabel
                                        active={orderBy === 'DRTG'}
                                        direction={orderBy === 'DRTG' ? orderDirection : 'desc'}
                                        onClick={() => handleSortRequest('DRTG')}
                                    >
                                        DRTG
                                    </TableSortLabel>
                                </TableCell>
                                <TableCell component="th" style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', textAlign: 'center', ...responsiveStyle, ...responsiveCellStyle }}>
                                    <TableSortLabel
                                        active={orderBy === 'NetRTG'}
                                        direction={orderBy === 'NetRTG' ? orderDirection : 'desc'}
                                        onClick={() => handleSortRequest('NetRTG')}
                                    >
                                        NetRTG
                                    </TableSortLabel>
                                </TableCell>
                                <TableCell component="th" style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', textAlign: 'center', ...responsiveStyle, ...responsiveCellStyle }}>
                                    <TableSortLabel
                                        active={orderBy === 'two_pt_pct'}
                                        direction={orderBy === 'two_pt_pct' ? orderDirection : 'desc'}
                                        onClick={() => handleSortRequest('two_pt_pct')}
                                    >
                                        2PT%
                                    </TableSortLabel>
                                </TableCell>
                                <TableCell component="th" style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', textAlign: 'center', ...responsiveStyle, ...responsiveCellStyle }}>
                                    <TableSortLabel
                                        active={orderBy === 'three_pt_pct'}
                                        direction={orderBy === 'three_pt_pct' ? orderDirection : 'desc'}
                                        onClick={() => handleSortRequest('three_pt_pct')}
                                    >
                                        3PT%
                                    </TableSortLabel>
                                </TableCell>
                                <TableCell></TableCell>
                                <TableCell component="th" style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', textAlign: 'center', ...responsiveStyle, ...responsiveCellStyle }}>
                                    <TableSortLabel
                                        active={orderBy === 'opp_two_pt_pct'}
                                        direction={orderBy === 'opp_two_pt_pct' ? orderDirection : 'desc'}
                                        onClick={() => handleSortRequest('opp_two_pt_pct')}
                                    >
                                        2PT%
                                    </TableSortLabel>
                                </TableCell>
                                <TableCell component="th" style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', textAlign: 'center', ...responsiveStyle, ...responsiveCellStyle }}>
                                    <TableSortLabel
                                        active={orderBy === 'opp_three_pt_pct'}
                                        direction={orderBy === 'opp_three_pt_pct' ? orderDirection : 'desc'}
                                        onClick={() => handleSortRequest('opp_three_pt_pct')}
                                    >
                                        3PT%
                                    </TableSortLabel>
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {sortedData.map((combination, index) => (
                                <TableRow key={index}>
                                    <TableCell className="left-column" sx={commonCellStyle} style={responsiveStyle}>
                                        {showJapanese 
                                            ? combination.player_names_with.map((name, index) => (
                                                <React.Fragment key={index}>
                                                    {name}{index < combination.player_names_with.length - 1 && <br />}
                                                </React.Fragment>
                                              ))
                                            : combination.player_names_eng_with.map((name, index) => (
                                                <React.Fragment key={index}>
                                                    {name.toUpperCase()}{index < combination.player_names_eng_with.length - 1 && <br />}
                                                </React.Fragment>
                                              ))
                                        }
                                    </TableCell>
                                    <TableCell className="left-column" sx={commonCellStyle} style={responsiveStyle}>
                                        {showJapanese 
                                            ? combination.player_names_without.map((name, index) => (
                                                <React.Fragment key={index}>
                                                    {name}{index < combination.player_names_without.length - 1 && <br />}
                                                </React.Fragment>
                                              ))
                                            : combination.player_names_eng_without.map((name, index) => (
                                                <React.Fragment key={index}>
                                                    {name.toUpperCase()}{index < combination.player_names_eng_without.length - 1 && <br />}
                                                </React.Fragment>
                                              ))
                                        }
                                    </TableCell>
                                    <TableCell className="left-column" sx={commonCellStyle} style={responsiveStyle}>{combination.possessions}</TableCell>
                                    <TableCell className="left-column" sx={commonCellStyle} style={responsiveStyle}>{combination.ORTG.toFixed(2)}</TableCell>
                                    <TableCell className="left-column" sx={commonCellStyle} style={responsiveStyle}>{combination.DRTG.toFixed(2)}</TableCell>
                                    <TableCell className="left-column" sx={commonCellStyle} style={responsiveStyle}>{combination.NetRTG > 0 ? `+${combination.NetRTG.toFixed(2)}` : combination.NetRTG.toFixed(2)}</TableCell>
                                    <TableCell className="left-column" sx={commonCellStyle} style={responsiveStyle}>{(combination.two_pt_pct * 100).toFixed(2)}%</TableCell>
                                    <TableCell className="left-column" sx={commonCellStyle} style={responsiveStyle}>{(combination.three_pt_pct * 100).toFixed(2)}%</TableCell>
                                    <TableCell className="left-column" sx={commonCellStyle}></TableCell>
                                    <TableCell className="left-column" sx={commonCellStyle} style={responsiveStyle}>{(combination.opp_two_pt_pct * 100).toFixed(2)}%</TableCell>
                                    <TableCell className="left-column" sx={commonCellStyle} style={responsiveStyle}>{(combination.opp_three_pt_pct * 100).toFixed(2)}%</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </div>
        </PageTransition>
    );
};

export default WOWYTable;
