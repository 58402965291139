// WOWYPage.js
import React, { useState, useEffect } from 'react';
import SeasonDropdownWOWY from './SeasonDropdownWOWY';
import LeagueDropdownWOWY from './LeagueDropdown_WOWY';
import TeamDropdown from './TeamDropdown';
import PlayersDropdown from './PlayersDropdown';
import FetchButton from './FetchButton';
import WOWYTable from './WOWYTable';
import LoadingAnimation from './LoadingAnimation';
import PageTransition from './PageTransition';
import axios from 'axios';
import { Box, Switch } from '@mui/material';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import './ImpactMetricsTable.css';

import { seasons } from './constants';

const baseUrl = process.env.REACT_APP_API_URL;

const instructionsEnglish = [
  "First select a team, then select 1 to 5 Players and click on the Fetch WOWY Data button to get the data.",
  "Data shown will be from games only in which all selected players were on the active roster.",
  "Selecting 4, 5 players could lead to small sample size for each lineup combination."
];

const instructionsJapanese = [
  "まずチームを選択し、その後1人以上、5人以下の選手を選択してからFetch WOWY Dataボタンを押してください。",
  "データは選択された選手全員がベンチ入りした試合の中からフィルターされます。",
  "4, 5人の選手を選択するとコンビネーション毎のサンプルサイズが小さくなり、極端な値が出る可能性があります。"
];

const WOWYPage = () => {
    // const { seasonId, leagueId } = useParams();
    const [selectedSeason, setSelectedSeason] = useState('202324');
    const [selectedLeague, setSelectedLeague] = useState('1'); // '1' for B1, '2' for B2
    const [teamList, setTeamList] = useState([]);
    const [playerList, setPlayerList] = useState([]);
    const [selectedTeam, setSelectedTeam] = useState('');
    const [selectedPlayers, setSelectedPlayers] = useState([]);
    const [wowyData, setWOWYData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);
    const [showJapanese, setShowJapanese] = useState(true);


    // Fetch teams when season or league changes
    useEffect(() => {
        axios.get(`${baseUrl}/api/team-list/`, { params: { season_id: selectedSeason, league_id: selectedLeague } })
            .then(response => setTeamList(response.data))
            .catch(error => console.error("Failed to fetch teams", error));
    }, [selectedSeason, selectedLeague]);

    // Fetch players when team changes
    useEffect(() => {
        if (!selectedTeam) return;
        axios.get(`${baseUrl}/api/team-players/`, { params: { team_id: selectedTeam, season_id: selectedSeason } })
            .then(response => {
                setPlayerList(response.data); // Ensure this matches the expected structure
            })
            .catch(error => console.error("Failed to fetch players", error));
    }, [selectedTeam, selectedSeason]);

    const handleFetchWOWY = () => {
        setIsLoading(true);
        setError(null);
        // Ensure multiple player_ids are correctly appended to the URL
        const playerIdsQuery = selectedPlayers.map(playerId => `player_ids[]=${playerId}`).join('&');
        axios.get(`${baseUrl}/api/wowy/?season_id=${selectedSeason}&team_id=${selectedTeam}&${playerIdsQuery}`)
            .then(response => {
                setWOWYData(response.data.wowy_combinations);
                setIsLoading(false);
            })
            .catch(error => {
                console.error("Failed to fetch WOWY data", error);
                setError("Failed to fetch data");
                setIsLoading(false);
            });
    };

    // Handler for when the season changes
    const handleSeasonChange = (newSeason) => {
        setSelectedSeason(newSeason);
        setSelectedTeam('');
        setSelectedPlayers([]);
    };

    // Handler for when the league changes
    const handleLeagueChange = (newLeague) => {
        setSelectedLeague(newLeague);
        setSelectedTeam('');
        setSelectedPlayers([]);
    };

    // Handler for when the team changes
    const handleTeamChange = (team) => {
        setSelectedTeam(team);
        setSelectedPlayers([]); // Clear the selected players array
    };

    const handleLanguageToggle = () => {
        setShowJapanese(!showJapanese);
    };

    console.log(selectedPlayers)
    return (
        <PageTransition>
            <Container maxWidth="lg" sx={{ my: 10 }}>
                <Typography variant="h4" gutterBottom className="impact-metrics-title">
                    WOWY Combinations
                </Typography>
                <Box
                    sx={{
                        display: "flex",
                        flexWrap: { xs: 'wrap', sm: 'nowrap' }, // Wrap on extra-small screens, no wrap on small screens and up
                        alignItems: "center",
                        overflowX: 'auto' // Horizontal scroll on overflow
                    }}
                >
                    <SeasonDropdownWOWY seasons={seasons} selectedSeason={selectedSeason} setSelectedSeason={handleSeasonChange} />
                    <LeagueDropdownWOWY selectedLeague={selectedLeague} setSelectedLeague={handleLeagueChange} />
                    <TeamDropdown teamList={teamList} selectedTeam={selectedTeam} setSelectedTeam={handleTeamChange} showJapanese={showJapanese} />
                    <PlayersDropdown 
                        playerList={playerList} 
                        selectedPlayers={selectedPlayers} 
                        setSelectedPlayers={setSelectedPlayers} 
                        showJapanese={showJapanese} 
                    />
                </Box>
                <Box
                    sx={{
                        display: "flex",
                        justifyContent: "space-between", // This spreads the children to each side
                        alignItems: "center",
                        pt: 2, // Padding top for space above this row
                        pb: 5, // Padding bottom for space below this row, before the WOWYTable
                    }}
                >
                    {/* Group for language switch and label */}
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <Typography variant="body2" sx={{ mr: 1 }}>
                            English
                        </Typography>
                        <Switch
                            checked={!showJapanese}
                            onChange={handleLanguageToggle}
                            name="languageToggle"
                            inputProps={{ 'aria-label': 'language toggle' }}
                            sx={{
                                // Style for the thumb color when checked
                                '& .MuiSwitch-switchBase.Mui-checked': {
                                    color: '#65FBD2', // Thumb color when checked
                                },
                                // Style for the track color when checked
                                '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
                                    backgroundColor: '#65FBD2', // Track color when checked
                                },
                                // Style for the track color in the initial state (not checked)
                                '& .MuiSwitch-track': {
                                    backgroundColor: '#ABABAB', // Default track color
                                },
                            }}
                        />
                    </Box>
                    {/* FetchButton aligned to the right */}
                    <FetchButton onClick={handleFetchWOWY} />
                </Box>
                {error && <p>{error}</p>}
                {isLoading ? (
                    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100px' }}>
                        <LoadingAnimation />
                    </Box>
                ) : (
                    <WOWYTable data={wowyData} showJapanese={showJapanese} />
                )}
                <Box sx={{ mt: 4, pt: 5 }}>
                  <Typography variant="body1">
                    {showJapanese ? instructionsJapanese.map((line, index) => (
                      <React.Fragment key={index}>
                        {index > 0 && <br />} {/* Add line break for lines after the first */}
                        &bull; {line}
                      </React.Fragment>
                    )) : instructionsEnglish.map((line, index) => (
                      <React.Fragment key={index}>
                        {index > 0 && <br />} {/* Add line break for lines after the first */}
                        &bull; {line}
                      </React.Fragment>
                    ))}
                  </Typography>
                </Box>
            </Container>
        </PageTransition>
    );
};

export default WOWYPage;