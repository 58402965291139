// TeamDropdown.js
import React from 'react';
import { FormControl, InputLabel, Select, MenuItem } from '@mui/material';

const TeamDropdown = ({ teamList, selectedTeam, setSelectedTeam, showJapanese }) => {
    const handleChange = (event) => {
        setSelectedTeam(event.target.value);
    };

    const sortedTeams = teamList.sort((a, b) => a.team_id - b.team_id);

    return (
        <FormControl className="impact-metrics-formControl" variant="outlined" sx={{ m: 1, minWidth: 150 }}>
            <InputLabel id="team-dropdown-label">Team</InputLabel>
            <Select
                labelId="team-dropdown-label"
                id="team-dropdown"
                value={selectedTeam}
                label="Team"
                onChange={handleChange}
            >
                {sortedTeams.map((team) => (
                    <MenuItem key={team.team_id} value={team.team_id}>
                        {showJapanese ? team.name_short : team.team_name_eng}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
};

export default TeamDropdown;
