// LeagueDropdown.js

import React from 'react';
import { FormControl, InputLabel, Select, MenuItem } from '@mui/material';

const LeagueDropdownWOWY = ({ selectedLeague, setSelectedLeague }) => {
    const handleLeagueChange = (event) => {
        setSelectedLeague(event.target.value);
    };

    const leagues = [
        { id: '1', name: 'B1' },
        { id: '2', name: 'B2' }
    ];

    return (
        <FormControl className="impact-metrics-formControl" variant="outlined" sx={{ m: 1, minWidth: 100 }}>
            <InputLabel>League</InputLabel>
            <Select
                value={selectedLeague}
                onChange={handleLeagueChange}
                label="League"
            >
                {leagues.map((league) => (
                    <MenuItem key={league.id} value={league.id}>
                        {league.name}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
};

export default LeagueDropdownWOWY;
