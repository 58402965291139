// Footer.js
import React from 'react';

const Footer = () => {
    return (
        <footer className="bg-grey text-black p-6">
            <div className="container mx-auto text-center">
                <p>© 2023 B League Impact Metrics. All rights reserved.</p>
            </div>
        </footer>
    );
};

export default Footer;
