// NavBar.js
import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons';
import SidebarNav from './SideNavBar'; // Adjust the path as necessary
import './NavBar.css';


const NavBar = () => {
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen);
    };

    return (
        <>
            {isMenuOpen && <div className="sidebar-overlay" onClick={() => setIsMenuOpen(false)}></div>}
            <SidebarNav menuState={isMenuOpen} setMenuState={setIsMenuOpen} />
            <header className="p-3 md:p-6 bg-black text-white">
                <nav className="container mx-auto flex justify-between items-center">
                    <div className="flex items-center gap-2 md:gap-4">
                        <img src="/images/icon.png" alt="Green Basketball Logo" className="h-8 md:h-12" />
                        <h1 className="text-xl md:text-3xl font-bold text-green">impactmetrics.jp</h1>
                    </div>
                    <div>
                        <button onClick={toggleMenu} className="text-green">
                           <FontAwesomeIcon icon={faBars} />
                        </button>   
                    </div>
                </nav>
            </header>
        </>
    );
};

export default NavBar;