import React from 'react';
import { Typography, Container, Divider, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Link } from '@mui/material';
import { createTheme, ThemeProvider, styled } from '@mui/material/styles'
import progressionImage from '../images/14301_progression.png';
import diagramImage from '../images/diagram_jp.png';
import './GPMExplanation.css';

const theme = createTheme({
    typography: {
        fontFamily: 'Roboto, sans-serif',
    },
});

const StyledTableContainer = styled(TableContainer)(({ theme }) => ({
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    padding: theme.spacing(2),
    maxWidth: 600,
    margin: '0 auto',
}));

const GPMExplanation = () => {
    const coefficients = [
        { variable: '2pm_p100', offensiveCoef: 0.9483799741500631, defenseCoef: 0.01993381108305096 },
        { variable: '2pa_p100', offensiveCoef: -0.39668327470586445, defenseCoef: -0.08863174161466622 },
        { variable: '3pm_p100', offensiveCoef: 1.439600863861926, defenseCoef: 0.1869176528911269 },
        { variable: '3pa_p100', offensiveCoef: -0.24339791216566434, defenseCoef: -0.046921137099587326 },
        { variable: 'ftm_p100', offensiveCoef: 0.68040739678691, defenseCoef: -0.1277251855525012 },
        { variable: 'fta_p100', offensiveCoef: -0.36174796963524386, defenseCoef: 0.12925413752168136 },
        { variable: 'oreb_p100', offensiveCoef: 0.38273604763848257, defenseCoef: 0.18635541649017787 },
        { variable: 'dreb_p100', offensiveCoef: -0.08117063304256983, defenseCoef: 0.08022843247274228 },
        { variable: 'ast_p100', offensiveCoef: 0.3554811216608242, defenseCoef: 0.17671812908161127 },
        { variable: 'stl_p100', offensiveCoef: 0.1652870064059297, defenseCoef: 0.6759657594829205 },
        { variable: 'blk_p100', offensiveCoef: -0.3208913548639053, defenseCoef: 0.6198466856576473 },
        { variable: 'tov_p100', offensiveCoef: -0.44744501506738665, defenseCoef: -0.24477435852614324 },
        { variable: 'pf_p100', offensiveCoef: -0.07286037606613935, defenseCoef: -0.03428207657011195 },
        { variable: 'Intercept', offensiveCoef: -3.1607567696276497, defenseCoef: -1.7343927280062554 },
    ];

    const paddingNumber = [
        { variable: '2pa_p100', paddingDenominator: 'poss', paddingValue: 46.45140634 },
        { variable: '2pt%', paddingDenominator: '2pa', paddingValue: 33.26763492 },
        { variable: '3pa_p100', paddingDenominator: 'poss', paddingValue: 42.32571546 },
        { variable: '3pt%', paddingDenominator: '3pa', paddingValue: 99.2809109 },
        { variable: 'fta_p100', paddingDenominator: 'poss', paddingValue: 112.6560117 },
        { variable: 'ft%', paddingDenominator: 'fta', paddingValue: 23.45084608 },
        { variable: 'orb_p100', paddingDenominator: 'poss', paddingValue: 83.62754119 },
        { variable: 'drb_p100', paddingDenominator: 'poss', paddingValue: 60.78853624 },
        { variable: 'ast_p100', paddingDenominator: 'poss', paddingValue: 65.27466162 },
        { variable: 'stl_p100', paddingDenominator: 'poss', paddingValue: 519.5630209 },
        { variable: 'blk_p100', paddingDenominator: 'poss', paddingValue: 184.1617414 },
        { variable: 'tov_p100', paddingDenominator: 'poss', paddingValue: 276.7496837 },
        { variable: 'pf_p100', paddingDenominator: 'poss', paddingValue: 106.8909181 },
    ];
    
    return (
        <ThemeProvider theme={theme}>
            <Container maxWidth="md" className="gpm-explanation">
                <img src={progressionImage} alt="Progression" style={{ width: '100%', marginBottom: theme.spacing(2) }} />
                <Typography variant="h4" component="h1" gutterBottom>
                    Introducing Guesstimated Plus-Minus
                </Typography>
                <Typography variant="body1" paragraph>
                    Guesstimated Plus-Minus (GPM) は、100ポゼッションあたりのチームの得点への貢献を推定するために作られた新たなオールインワンメトリクスです。GPMはNBA界隈の先人たちが作った指標を参考に、気に入ったコンセプトを選んで組み合わせたような指標になっています。NBAの世界でこれだけのものが、これだけオープンになっているからこそGPMは作ることができたので、この解説で引用をする方々にはお礼を申し上げたいと思います。参考にするものを選定していく際には、Bリーグというリーグの特色にマッチしていると感じたコンセプトを取り入れたり、リーグにあわせた調整を行っており、これによってNBAの指標をそのまま真似するよりもBリーグという文脈にフィットした指標にGPMはなっていると期待しています。
                </Typography>
                <Typography variant="body1" paragraph>
                    大まかなステップとしては、ボックススコアスタッツから計算をしたSPMを事前分布とし、
                    <Link href="https://squared2020.com/2017/09/18/deep-dive-on-regularized-adjusted-plus-minus-i-introductory-example/" target="_blank" rel="noopener noreferrer" color="#8685EF" sx={{ textDecoration: 'none', '&:hover': { color: "#65FBD2", textDecoration: 'underline' } }}>
                        RAPM
                    </Link>
                    {' '}モデルに与えるもので、ベイズ的RAPMとアメリカで呼ばれているものに習います。個人的な理解では、Jerry Engelmannの{' '}
                    <Link href="https://www.espn.co.uk/nba/story/_/id/10740818/introducing-real-plus-minus" target="_blank" rel="noopener noreferrer" color="#8685EF" sx={{ textDecoration: 'none', '&:hover': { color: "#65FBD2", textDecoration: 'underline' } }}>
                        xRAPM (ESPNの旧RPMモデル) 
                    </Link>
                    {' '}、Taylor Snarrの{' '}
                    <Link href="https://dunksandthrees.com/about/epm" target="_blank" rel="noopener noreferrer" color="#8685EF" sx={{ textDecoration: 'none', '&:hover': { color: "#65FBD2", textDecoration: 'underline' } }}>
                        EPM
                    </Link>
                    {' '}とKrishna Narsuの{' '}
                    <Link href="https://www.bball-index.com/lebron-introduction/" target="_blank" rel="noopener noreferrer" color="#8685EF" sx={{ textDecoration: 'none', '&:hover': { color: "#65FBD2", textDecoration: 'underline' } }}>
                        LEBRON
                    </Link>
                    {' '}はいずれもこの枠組みに従っていて、高い評価を受けている指標です。
                </Typography>
                <Divider style={{ margin: '20px 0' }} />
                <Typography variant="h5" component="h2" gutterBottom>
                    Motivation
                </Typography>
                <Typography variant="body1" paragraph>
                    これまでもオールインワンメトリクスに興味を持っており、それぞれの作成者が公開してくださっている手順のおかげで、Bリーグのデータを使ってJacob Goldsteinの{' '}
                    <Link href="https://www.bball-index.com/player-impact-plus-minus/" target="_blank" rel="noopener noreferrer" color="#8685EF" sx={{ textDecoration: 'none', '&:hover': { color: "#65FBD2", textDecoration: 'underline' } }}>
                        PIPM
                    </Link>
                    {' '}とDaniel Myersの{' '}
                    <Link href="https://www.basketball-reference.com/about/bpm2.html" target="_blank" rel="noopener noreferrer" color="#8685EF" sx={{ textDecoration: 'none', '&:hover': { color: "#65FBD2", textDecoration: 'underline' } }}>
                        BPM
                    </Link>
                    {' '}を再現する取り組みをしています。今年に入ってプレイバイプレイからRAPMを算出できるようになり、2017-18シーズンまで遡ってデータを収集し、計7年分のBリーグRAPMの計算ができました。このサンプルが用意できたこともあり、日本のBリーグに特化した独自の指標を作る興味が湧き、粛々と準備をしてきました。私の知る限り、GPMはBリーグ向けに作られ、一般公開される最初のオールインワンメトリクスであり、また一般公開される最初のベイズ的RAPMモデルとなります。
                </Typography>
                <Typography variant="body1" paragraph>
                    ベイズ的RAPMの手法を選んだ理由としては、シンプルにNBAでの評判によるものです。完璧なオールインワンメトリクスは存在しませんが、存在するものの中で良いとされていて、かつ自分が好きなものはほとんどこの手法を使用しています。BPMではボックススコアのみ、PIPMではボックススコアとOn/Offのデータを使用して計算が行われますが、GPM (ベイズ的RAPM) ではOn/Offのデータの部分をRAPMの計算が担うことになります。GPM (Guesstimated Plus-Minus) という名称は（明らかに）EPMを意識して名付けたものとなります。EPMの説明では使用する変数が明示されておらず、再現は不可能ですが、説明の中から読み取れたEPMの哲学に最も影響を受けてGPMの開発がされています。
                </Typography>
                <Divider style={{ margin: '20px 0' }} />
                <Typography variant="h5" component="h2" gutterBottom>
                    The Box Score Component (Box-GPM)
                </Typography>
                <Typography variant="body1" paragraph>
                    ボックススコアのパート (Box-GPM) は、100ポゼッション換算をしたシンプルなボックススコアスタッツを使って計算されます。係数は、B1の7年RAPMサンプルを使って計算しました。選定した変数に関してはPIPMと似ていますが（PIPMは36分換算というところが違い）、GPMでは意図的にスターターか否かというデータを含めませんでした。Bリーグでは、ロスターとオンコートの両方で外国籍選手の人数に制限があるため、良い選手がベンチスタートの場合があるからです。
                </Typography>
                <Typography variant="body1" paragraph>
                    また、Box-GPMは、Kostya Medvedovskyが{' '}
                    <Link href="https://kmedved.com/2020/08/06/nba-stabilization-rates-and-the-padding-approach/" target="_blank" rel="noopener noreferrer" color="#8685EF" sx={{ textDecoration: 'none', '&:hover': { color: "#65FBD2", textDecoration: 'underline' } }}>
                        こちら
                    </Link>
                    {' '}で説明している方法を使ってそれぞれの100ポゼッション換算のスタッツにPaddingをしています。各スタッツのPadding Numberは、7年間のボックススコアを使って、差分進化によって決定しました。Paddingをすることで、特に小さなサンプルでのBox-GPMを安定させることができ、事前分布を作るにあたって重要な役割を果たします。オールインワンメトリクスでPaddingを使うというアイデアは多くのメトリクスで採用されていますが、代表的なものはNBACouchsideの{' '}
                    <Link href="https://nbacouchside.net/2022/11/05/introducing-nba-stable-player-impact-spi/" target="_blank" rel="noopener noreferrer" color="#8685EF" sx={{ textDecoration: 'none', '&:hover': { color: "#65FBD2", textDecoration: 'underline' } }}>
                        SPI
                    </Link>
                    {' '}でしょう。個人的な意見としては、各100ポゼッション換算のスタッツをそれぞれ最適な数値でパディングすることは、従来の指標がReplacement Levelのポゼッションを結果の値にPaddingするというやり方よりも合理的で良いと思います。これにより、Box-GPMは、小さなサンプルで良いパフォーマンスをした選手を既存の指標より素早くキャッチできるようになっています。NBAと違い、Bリーグをはじめとする海外のリーグは、リーグのレベルからみて優れた選手がシーズン途中に別のリーグからやってくることがあるため、そのような選手を早く正しく評価できるということは特に重要だと感じます。
                </Typography>
                <Typography variant="body1" paragraph>
                    Box-GPMをPaddingした後、選手がプレーしたポゼッションの割合を考慮し、リーグ全体のBox-GPMの合計がゼロになるように均一の値を足します (実際に足す値はマイナスの値になるので引き算になります) 。これはBPMのTeam Adjustmentに非常に似ている調整ですが、チームごとではなくリーグ全体で一度だけ行われます。ここではEPMの哲学に習い、チーム毎ではなくリーグ全体でこの調整を行っています。EPMでは、たとえRAPMに対するR2がこの決断によって低くなろうが、チームレベルのスタッツ (チームのORTGやDRTGなど) を含めないようにしているそうです。GPMでも同様、チームレベルのスタッツは一切使わず、ここでも調整をリーグ全体で行っています。
                </Typography>
                <Typography variant="body1" paragraph>
                    以下がBox-GPMの計算に使われる各スタッツの係数とPadding Numberとなります。
                </Typography>
                <Typography variant="h5" component="h3" gutterBottom>
                    Padding Numbers
                </Typography>
                <StyledTableContainer>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell sx={{ fontWeight: 'bold' }}>Statistic</TableCell>
                                <TableCell sx={{ fontWeight: 'bold' }}>Padding Denominator</TableCell>
                                <TableCell sx={{ fontWeight: 'bold' }}>Padding Value</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {paddingNumber.map((row, index) => (
                                <TableRow key={index}>
                                    <TableCell component="th" scope="row">
                                        {row.variable}
                                    </TableCell>
                                    <TableCell>{row.paddingDenominator}</TableCell>
                                    <TableCell>{row.paddingValue}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </StyledTableContainer>
                <Typography variant="h5" component="h3" gutterBottom>
                    Coefficients
                </Typography>
                <StyledTableContainer>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell sx={{ fontWeight: 'bold' }}>Statistic</TableCell>
                                <TableCell sx={{ fontWeight: 'bold' }}>Offensive Coef.</TableCell>
                                <TableCell sx={{ fontWeight: 'bold' }}>Defensive Coef.</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {coefficients.map((row, index) => (
                                <TableRow key={index}>
                                    <TableCell component="th" scope="row">
                                        {row.variable}
                                    </TableCell>
                                    <TableCell>{row.offensiveCoef}</TableCell>
                                    <TableCell>{row.defenseCoef}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </StyledTableContainer>
                <Divider style={{ margin: '20px 0' }} />
                <Typography variant="h5" component="h2" gutterBottom>
                    RAPM Calculation
                </Typography>
                <Typography variant="body1" paragraph>
                    GPMを計算する最後のステップは、RAPMの計算です。通常、1シーズンのみのデータを使ったRAPMではあまり良い結果は得られませんが、ベイズ的RAPMでは事前分布をRAPMモデルに与え、これによりRAPMの計算を実行するにあたり全選手を0地点から始めるのではなく、よりゴール (本質的なインパクトの値) に近いところから計算を開始することができます。GPMでは、Box-GPMを事前分布とし、単一シーズンのRAPMの計算を行います。GPMの計算の最終ステップとしてRAPMの計算を行うことで、チームメイトのパフォーマンスを考慮に入れない単純なOn/Offスタッツを使用するよりも、より複雑なニュアンスをとらえることができます。
                </Typography>
                <Typography variant="body1" paragraph>
                    また、RAPMを計算する際には、リッジ回帰のλ値を慎重に決める必要があります。交差検証によって最適化することはできますが、単一シーズンのRAPMを計算するうえでは、交差検証が提示するよりも高いλを使用すると、最終的により良い結果が得られることがわかりました。これは、RAPMの計算をするには単一シーズンというサンプルが十分ではなく、その小さなサンプル内で交差検証を行うことがλ値を最適化する最良の方法ではないためだと思われます。EPMでも「A fairly strong lambda(比較的強いλ)」を使用していると述べており、事前分布の値から通常+/-1程度変動するとのことです。GPMでは、この変動は+/-2程度になるようなλにしています。
                </Typography>
                <Typography variant="body1" paragraph>
                    このベイズ的RAPMの計算は、{' '}
                    <Link href="https://github.com/rd11490/NBA_Tutorials/tree/master/rapm_prior" target="_blank" rel="noopener noreferrer" color="#8685EF" sx={{ textDecoration: 'none', '&:hover': { color: "#65FBD2", textDecoration: 'underline' } }}>
                        こちらのRyan Davisのチュートリアル
                    </Link>
                    {' '}を参考にして行われました。これはGitHubで公開されており、かつLEBRONを担当するBBall Indexによっても推奨もされているものです。参考とするものがなければ、コーディングの作業にかかった時間は遥かに長くなっていたことでしょう。
                </Typography>
                <Typography variant="body1" paragraph>
                    説明は以上です。実際のGuesstimated Plus-Minusの結果は、{' '}
                    <Link href="/gpm/" target="_blank" rel="noopener noreferrer" color="#8685EF" sx={{ textDecoration: 'none', '&:hover': { color: "#65FBD2", textDecoration: 'underline' } }}>
                        こちら
                    </Link>
                    {' '}にて公開をしております。
                </Typography>
                <Typography variant="body1" paragraph>
                    長文になってしまったので、以下簡単なGPMの計算の流れを書いたダイアグラムとなります。
                </Typography>
                <img src={diagramImage} alt="Diagram" style={{ width: '100%', marginBottom: theme.spacing(2) }} />
            </Container>
        </ThemeProvider>
    );
};

export default GPMExplanation;