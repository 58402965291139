import React from 'react';
import { FormControl, InputLabel, Select, MenuItem, OutlinedInput } from '@mui/material';

const PlayersDropdown = ({ playerList, selectedPlayers, setSelectedPlayers, showJapanese }) => {
    const handleChange = (event) => {
        setSelectedPlayers(event.target.value); // Since MUI's Select with `multiple` should already provide an array
    };

    const sortedPlayers = playerList.sort((a, b) => a.player_id.localeCompare(b.player_id, 'en', { numeric: true }));

    return (
        <FormControl className="impact-metrics-formControl" variant="outlined" fullWidth>
            <InputLabel>Players</InputLabel>
            <Select
                multiple
                value={selectedPlayers} // Ensure it's always an array
                onChange={handleChange}
                input={<OutlinedInput label="Players" />}
            // other props
            >
                {sortedPlayers.map((player) => (
                    <MenuItem key={player.player_id} value={player.player_id}>
                        {showJapanese ? player.player_name : player.player_name_eng}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
};

export default PlayersDropdown;