// ColoringLogic.js
import React from 'react';
import { TableCell } from '@mui/material';

export const calculatePercentiles = (data, metrics, reverseMetrics = []) => {
    let percentileData = {};
    metrics.forEach(metric => {
        const values = data.map(player => player[metric]);
        let uniqueValues = [...new Set(values)].sort((a, b) => a - b);
        if (reverseMetrics.includes(metric)) {
            uniqueValues = uniqueValues.reverse();
        }
        percentileData[metric] = uniqueValues.reduce((acc, value, index, array) => {
            acc[value] = (index + 1) / array.length;
            return acc;
        }, {});
    });
    return percentileData;
};

export const interpolateColor = (color1, color2, factor) => {
    const r1 = parseInt(color1.slice(1, 3), 16);
    const g1 = parseInt(color1.slice(3, 5), 16);
    const b1 = parseInt(color1.slice(5, 7), 16);

    const r2 = parseInt(color2.slice(1, 3), 16);
    const g2 = parseInt(color2.slice(3, 5), 16);
    const b2 = parseInt(color2.slice(5, 7), 16);

    const r = Math.round(r1 + factor * (r2 - r1));
    const g = Math.round(g1 + factor * (g2 - g1));
    const b = Math.round(b1 + factor * (b2 - b1));

    return '#' + r.toString(16).padStart(2, '0') + g.toString(16).padStart(2, '0') + b.toString(16).padStart(2, '0');
};


export const getColor = (factor, reverse = false) => {
    const color1 = "#FFA46A"; // Red (worse)
    const color2 = "#FAF8FF"; // White (neutral)
    const color3 = "#65FBD2"; // Green (better)
    
    let adjustedFactor = reverse ? 1 - factor : factor;
    let backgroundColor;
    
    if (adjustedFactor < 0.5) {
        backgroundColor = interpolateColor(color1, color2, adjustedFactor * 2);
    } else {
        backgroundColor = interpolateColor(color2, color3, (adjustedFactor - 0.5) * 2);
    }
    
    const hoverColor = darkenColor(backgroundColor, 0.1);
    return { backgroundColor, hoverColor };
};

export const darkenColor = (color, factor = 0.1) => {
    color = color.replace('#', '');
    let r = parseInt(color.substr(0, 2), 16);
    let g = parseInt(color.substr(2, 2), 16);
    let b = parseInt(color.substr(4, 2), 16);

    r = Math.floor(r * (1 - factor));
    g = Math.floor(g * (1 - factor));
    b = Math.floor(b * (1 - factor));

    r = Math.min(255, Math.max(0, r));
    g = Math.min(255, Math.max(0, g));
    b = Math.min(255, Math.max(0, b));

    return `#${((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1)}`;
};

const ColoredCell = ({ value, percentile, format, sx = {}, className = '', reverse = false }) => {
    const { backgroundColor } = getColor(percentile, reverse);
    const responsiveStyle = window.innerWidth <= 600 ? { fontSize: '11px' } : {};
    const responsiveCellStyle = window.innerWidth <= 600 ? { padding: '2px', minWidth: '30px' } : { padding: '2px', minWidth: '80px' };

    return (
        <TableCell
            sx={{
                ...sx,
                ...responsiveCellStyle,
                backgroundColor,
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                textAlign: 'center',
                '&.MuiTableCell-root': {
                    transition: 'filter 0.2s ease',
                },
            }}
            className={className}
            style={responsiveStyle}
        >
            {format ? format(value) : value}
        </TableCell>
    );
};

export default ColoredCell;