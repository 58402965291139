import React, { useEffect, useRef, useState } from 'react';
import { ParallaxProvider, useParallax } from 'react-scroll-parallax';
import { gsap } from 'gsap';
import LeagueSummary from './LeagueSummary';

const ParallaxContent = () => {
    const [showStats, setShowStats] = useState(false);
    const statsRef = useRef(null);
    const contentRef = useRef(null);
    const isMobile = window.innerWidth <= 767; // Simple mobile detection

    const { ref } = useParallax({ speed: isMobile ? 0 : 10 });

    useEffect(() => {
        gsap.fromTo(
            contentRef.current.children,
            { y: 20, opacity: 0 },
            {
                y: 0,
                opacity: 1,
                duration: 0.8,
                stagger: 0.15,
                ease: "power2.out",
            }
        );

        const observer = new IntersectionObserver(
            ([entry]) => {
                if (entry.isIntersecting) {
                    setShowStats(true);
                    observer.unobserve(entry.target);
                }
            },
            { threshold: 0.1 }
        );

        if (statsRef.current) {
            observer.observe(statsRef.current);
        }

        return () => observer.disconnect();
    }, []);

    return (
        <section className="min-h-[150vh] md:min-h-[200vh] bg-[#38292C] relative overflow-hidden">
            <div ref={ref} className="h-screen flex items-center justify-center relative z-10">
                <div ref={contentRef} className="max-w-4xl mx-auto text-center px-4">
                    <h1 className="text-4xl md:text-6xl font-bold mb-4 md:mb-6 text-[#FAF8FF]">
                        Welcome to the Home of
                        <span className="block mt-2 text-[#65FBD2] relative">
                            Guesstimated Plus-Minus
                            <svg className="absolute -bottom-1 md:-bottom-2 left-0 w-full" viewBox="0 0 300 12" xmlns="http://www.w3.org/2000/svg">
                                <path d="M1 5.5Q75 1 150 5.5T299 5.5" stroke="#65FBD2" strokeWidth="2" fill="none" />
                            </svg>
                        </span>
                    </h1>
                    <p className="text-lg md:text-xl mb-6 md:mb-8 text-[#FAF8FF]">
                        Bリーグの Impact Metrics 等、アドバンスドスタッツを公開しています
                    </p>
                    <button
                        className="bg-[#65FBD2] text-[#38292C] px-6 py-3 md:px-8 md:py-3 rounded-full font-semibold hover:bg-[#ABABAB] transition-colors duration-300 shadow-md text-base md:text-lg"
                        onClick={() => window.location.href = '/gpm/'}
                    >
                        Explore GPM
                    </button>
                </div>
            </div>
            <div ref={statsRef} className="min-h-screen flex items-center justify-center relative z-10">
                {showStats && <LeagueSummary />}
            </div>
        </section>
    );
};

const EnhancedHeroSection = () => {
    return (
        <ParallaxProvider>
            <ParallaxContent />
        </ParallaxProvider>
    );
};

export default EnhancedHeroSection;