import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import { useNavigate } from 'react-router-dom';
import { Box, Typography, Button } from '@mui/material';

const BasketballSVG = () => (
    <svg id="basketball-icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" width="150" height="150"> {/* Increased size to 150x150 */}
        <title>Basketball</title>
        <g id="basketball">
            <path className="cls-2" d="M51.62,78.15a31,31,0,1,0-31-31,31,31,0,0,0,31,31Z" fill="#65FBD2" />
            <path className="cls-3" d="M23.83,61c1.52-10.43,22.12-42.38,49.46-35.67" fill="none" stroke="#38292C" strokeWidth="2" />
            <path className="cls-3" d="M45,16.77C35,23.55,27.11,40.56,44,46.45c11.52,4,31,.86,37.85,7.83" fill="none" stroke="#38292C" strokeWidth="2" />
            <path className="cls-3" d="M24.74,31.56C26.26,42,46.86,73.94,74.2,67.24M20.67,44.64c13.52,4.37,7,22.17,23.89,32.69" fill="none" stroke="#38292C" strokeWidth="2" />
        </g>
        <g id="basketball-air">
            <line className="cls-1" x1="29.99" y1="75.82" x2="11.47" y2="94.33" stroke="#38292C" strokeWidth="1" />
            <line className="cls-1" x1="25.18" y1="72.25" x2="12.19" y2="85.24" stroke="#38292C" strokeWidth="1" />
            <line className="cls-1" x1="21.75" y1="67.01" x2="16" y2="72.76" stroke="#38292C" strokeWidth="1" />
        </g>
    </svg>
);

const NotFound = () => {
    const navigate = useNavigate();
    const [bounceHeight, setBounceHeight] = useState(0);

    useEffect(() => {
        const interval = setInterval(() => {
            setBounceHeight(Math.random() * 30 + 15); // Adjust bounce height range
        }, 1000);
        return () => clearInterval(interval);
    }, []);

    return (
        <Box
            sx={{
                height: '100vh',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                backgroundColor: '#38292C',
                color: '#FAF8FF',
                overflow: 'hidden',
                position: 'relative',
            }}
        >
            <Typography variant="h1" sx={{ fontSize: '8rem', fontWeight: 'bold', mb: 2, display: 'flex', alignItems: 'center' }}>
                4
                <motion.div
                    animate={{
                        y: [0, -bounceHeight, 0],
                    }}
                    transition={{
                        y: { duration: 1, repeat: Infinity, repeatType: "loop" },
                        rotate: { duration: 2, repeat: Infinity, repeatType: "loop", ease: "linear" }
                    }}
                    style={{ display: 'inline-block', margin: '0 10px' }}
                >
                    <BasketballSVG />
                </motion.div>
                4
            </Typography>

            <Typography variant="h4" sx={{ mb: 4, textAlign: 'center' }}>
                Oops! This page is not found.
            </Typography>

            <Button
                variant="contained"
                onClick={() => navigate('/')}
                sx={{
                    backgroundColor: '#65FBD2',
                    color: '#38292C',
                    '&:hover': {
                        backgroundColor: '#ABABAB',
                    },
                }}
            >
                Back to Home
            </Button>
        </Box>
    );
};

export default NotFound;