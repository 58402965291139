// SeasonDropdown.js
import React from 'react';
import { FormControl, InputLabel, Select, MenuItem } from '@mui/material';

const SeasonDropdown = ({ seasons, selectedSeason, setSelectedSeason }) => {
    const handleSeasonChange = (event) => {
        setSelectedSeason(event.target.value);
    };

    return (
        <FormControl sx={{ m: 1, minWidth: 150 }}>
            <InputLabel id="season-select-label">Season</InputLabel>
            <Select
                labelId="season-select-label"
                id="season-select"
                value={selectedSeason}
                label="SEASON"
                onChange={handleSeasonChange}
            >
                {seasons.map((season) => (
                    <MenuItem key={season} value={season}>
                        {`${season.substring(0, 4)}-${season.substring(4)}`}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
};

export default SeasonDropdown;
